@charset "UTF-8";
/* Common */

/* Layout */

/* Theme */

/* Compact */

.fa-sort.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.fa-sort.fa:before {
  content: "sort";
}

.fa-sort-asc.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.fa-sort-asc.fa:before {
  content: "keyboard_arrow_up";
}

.fa-sort-desc.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.fa-sort-desc.fa:before {
  content: "keyboard_arrow_down";
}

.fa-angle-up {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.fa-angle-up.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.fa-angle-up.fa:before {
  content: "play_arrow";
}

.fa-angle-double-up {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.fa-angle-double-up.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.fa-angle-double-up.fa:before {
  content: "skip_next";
}

.fa-angle-down {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.fa-angle-down.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.fa-angle-down.fa:before {
  content: "play_arrow";
}

.fa-angle-double-down {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.fa-angle-double-down.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.fa-angle-double-down.fa:before {
  content: "skip_previous";
}

.fa-angle-right.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.fa-angle-right.fa:before {
  content: "play_arrow";
}

.fa-angle-double-right.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.fa-angle-double-right.fa:before {
  content: "fast_forward";
}

.fa-angle-left {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.fa-angle-left.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.fa-angle-left.fa:before {
  content: "play_arrow";
}

.fa-angle-double-left.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.fa-angle-double-left.fa:before {
  content: "fast_rewind";
}

.fa-check.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.fa-check.fa:before {
  content: "check";
}

.fa-caret-up.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.fa-caret-up.fa:before {
  content: "keyboard_arrow_up";
}

.fa-caret-down.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.fa-caret-down.fa:before {
  content: "keyboard_arrow_down";
}

.fa-caret-right.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.fa-caret-right.fa:before {
  content: "keyboard_arrow_right";
}

.fa-caret-left.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.fa-caret-left.fa:before {
  content: "keyboard_arrow_left";
}

.fa-search.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.fa-search.fa:before {
  content: "search";
}

.fa-close.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.fa-close.fa:before {
  content: "close";
}

.fa-minus.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.fa-minus.fa:before {
  content: "remove";
}

.fa-plus.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.fa-plus.fa:before {
  content: "add";
}

.fa-check.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.fa-check.fa:before {
  content: "check";
}

.fa-circle.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.fa-circle.fa:before {
  content: "circle";
}

.fa-info-circle.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.fa-info-circle.fa:before {
  content: "info";
}

.fa-upload.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.fa-upload.fa:before {
  content: "file_upload";
}

.fa-chevron-circle-left.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.fa-chevron-circle-left.fa:before {
  content: "keyboard_arrow_left";
}

.fa-chevron-circle-right.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.fa-chevron-circle-right.fa:before {
  content: "keyboard_arrow_right";
}

.fa-chevron-circle-down.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.fa-chevron-circle-down.fa:before {
  content: "keyboard_arrow_down";
}

.fa-chevron-circle-up.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.fa-chevron-circle-up.fa:before {
  content: "keyboard_arrow_up";
}

.fa-home.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.fa-home.fa:before {
  content: "home";
}

.fa-chevron-right.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.fa-chevron-right.fa:before {
  content: "chevron_right";
}

.fa-chevron-left.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.fa-chevron-left.fa:before {
  content: "chevron_left";
}

.fa-circle-o.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.fa-circle-o.fa:before {
  content: "radio_button_unchecked";
}

.fa-dot-circle-o.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.fa-dot-circle-o.fa:before {
  content: "radio_button_checked";
}

.fa-arrow-circle-right.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.fa-arrow-circle-right.fa:before {
  content: "play_circle_outline";
}

.fa-arrow-circle-left {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.fa-arrow-circle-left.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.fa-arrow-circle-left.fa:before {
  content: "play_circle_outline";
}

.fa-calendar.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.fa-calendar.fa:before {
  content: "date_range";
}

.fa-arrow-down.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.fa-arrow-down.fa:before {
  content: "arrow_downward";
}

.fa-arrow-up.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.fa-arrow-up.fa:before {
  content: "arrow_upward";
}

.ui-icon-carat-2-n-s.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-carat-2-n-s.fa:before {
  content: "sort";
}

.ui-icon-triangle-1-n.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-triangle-1-n.fa:before {
  content: "keyboard_arrow_up";
}

.ui-icon-triangle-1-e.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-triangle-1-e.fa:before {
  content: "keyboard_arrow_right";
}

.ui-icon-triangle-1-s.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-triangle-1-s.fa:before {
  content: "keyboard_arrow_down";
}

.ui-icon-triangle-1-w.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-triangle-1-w.fa:before {
  content: "keyboard_arrow_left";
}

.ui-icon-carat-1-n.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-carat-1-n.fa:before {
  content: "keyboard_arrow_up";
}

.ui-icon-carat-1-e.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-carat-1-e.fa:before {
  content: "keyboard_arrow_right";
}

.ui-icon-carat-1-s.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-carat-1-s.fa:before {
  content: "keyboard_arrow_down";
}

.ui-icon-carat-1-w.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-carat-1-w.fa:before {
  content: "keyboard_arrow_left";
}

.ui-icon-arrow-1-n {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.ui-icon-arrow-1-n.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-arrow-1-n.fa:before {
  content: "play_arrow";
}

.ui-icon-arrowstop-1-n {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.ui-icon-arrowstop-1-n.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-arrowstop-1-n.fa:before {
  content: "skip_next";
}

.ui-icon-arrow-1-s {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.ui-icon-arrow-1-s.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-arrow-1-s.fa:before {
  content: "play_arrow";
}

.ui-icon-arrowstop-1-s {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.ui-icon-arrowstop-1-s.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-arrowstop-1-s.fa:before {
  content: "skip_previous";
}

.ui-icon-arrow-1-w {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.ui-icon-arrow-1-w.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-arrow-1-w.fa:before {
  content: "play_arrow";
}

.ui-icon-arrowstop-1-w.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-arrowstop-1-w.fa:before {
  content: "fast_rewind";
}

.ui-icon-arrow-1-e.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-arrow-1-e.fa:before {
  content: "play_arrow";
}

.ui-icon-arrowstop-1-e.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-arrowstop-1-e.fa:before {
  content: "fast_forward";
}

.ui-icon-arrowthick-1-s {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.ui-icon-arrowthick-1-s.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-arrowthick-1-s.fa:before {
  content: "play_arrow";
}

.ui-icon-arrowthick-1-n {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.ui-icon-arrowthick-1-n.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-arrowthick-1-n.fa:before {
  content: "play_arrow";
}

.ui-icon-circle-triangle-e.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-circle-triangle-e.fa:before {
  content: "play_circle_outline";
}

.ui-icon-circle-triangle-w {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.ui-icon-circle-triangle-w.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-circle-triangle-w.fa:before {
  content: "play_circle_outline";
}

.ui-icon-circle-triangle-s {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.ui-icon-circle-triangle-s.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-circle-triangle-s.fa:before {
  content: "play_circle_outline";
}

.ui-icon-radio-off.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-radio-off.fa:before {
  content: "radio_button_unchecked";
}

.ui-icon-radio-on.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-radio-on.fa:before {
  content: "radio_button_checked";
}

.ui-icon-folder-collapsed.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-folder-collapsed.fa:before {
  content: "folder";
}

.ui-icon-document.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-document.fa:before {
  content: "content_paste";
}

.ui-icon-video.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-video.fa:before {
  content: "movie";
}

.ui-icon-music.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-music.fa:before {
  content: "music_note";
}

.ui-icon-plus.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-plus.fa:before {
  content: "add";
}

.ui-icon-minus.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-minus.fa:before {
  content: "remove";
}

.ui-icon-plusthick.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-plusthick.fa:before {
  content: "add";
}

.ui-icon-minusthick.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-minusthick.fa:before {
  content: "remove";
}

.ui-icon-pencil.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-pencil.fa:before {
  content: "edit";
}

.ui-icon-closethick.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-closethick.fa:before {
  content: "close";
}

.ui-icon-circle-close.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-circle-close.fa:before {
  content: "cancel";
}

.ui-icon-gear.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-gear.fa:before {
  content: "settings";
}

.ui-icon-calendar.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-calendar.fa:before {
  content: "date_range";
}

.ui-icon-trash.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-trash.fa:before {
  content: "delete_forever";
}

.ui-icon-notice.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-notice.fa:before {
  content: "error_outline";
}

.ui-icon-alert.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-alert.fa:before {
  content: "warning";
}

.ui-icon-circle-zoomin.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-circle-zoomin.fa:before {
  content: "zoom_in";
}

.ui-icon-circle-zoomout.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-circle-zoomout.fa:before {
  content: "zoom_out";
}

.ui-icon-circle-arrow-e.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-circle-arrow-e.fa:before {
  content: "play_circle_outline";
}

.ui-icon-circle-arrow-w {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.ui-icon-circle-arrow-w.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-circle-arrow-w.fa:before {
  content: "play_circle_outline";
}

.ui-icon-3d-rotation.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-3d-rotation.fa:before {
  content: "3d_rotation";
}

.ui-icon-ac-unit.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-ac-unit.fa:before {
  content: "ac_unit";
}

.ui-icon-access-alarm.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-access-alarm.fa:before {
  content: "access_alarm";
}

.ui-icon-access-alarms.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-access-alarms.fa:before {
  content: "access_alarms";
}

.ui-icon-access-time.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-access-time.fa:before {
  content: "access_time";
}

.ui-icon-accessibility.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-accessibility.fa:before {
  content: "accessibility";
}

.ui-icon-accessible.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-accessible.fa:before {
  content: "accessible";
}

.ui-icon-account-balance.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-account-balance.fa:before {
  content: "account_balance";
}

.ui-icon-account-balance-wallet.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-account-balance-wallet.fa:before {
  content: "account_balance_wallet";
}

.ui-icon-account-box.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-account-box.fa:before {
  content: "account_box";
}

.ui-icon-account-circle.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-account-circle.fa:before {
  content: "account_circle";
}

.ui-icon-add.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-add.fa:before {
  content: "add";
}

.ui-icon-add-a-photo.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-add-a-photo.fa:before {
  content: "add_a_photo";
}

.ui-icon-add-alarm.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-add-alarm.fa:before {
  content: "add_alarm";
}

.ui-icon-add-alert.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-add-alert.fa:before {
  content: "add_alert";
}

.ui-icon-add-box.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-add-box.fa:before {
  content: "add_box";
}

.ui-icon-add-circle.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-add-circle.fa:before {
  content: "add_circle";
}

.ui-icon-add-circle-outline.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-add-circle-outline.fa:before {
  content: "add_circle_outline";
}

.ui-icon-add-location.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-add-location.fa:before {
  content: "add_location";
}

.ui-icon-add-shopping-cart.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-add-shopping-cart.fa:before {
  content: "add_shopping_cart";
}

.ui-icon-add-to-photos.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-add-to-photos.fa:before {
  content: "add_to_photos";
}

.ui-icon-add-to-queue.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-add-to-queue.fa:before {
  content: "add_to_queue";
}

.ui-icon-adjust.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-adjust.fa:before {
  content: "adjust";
}

.ui-icon-airline-seat-flat.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-airline-seat-flat.fa:before {
  content: "airline_seat_flat";
}

.ui-icon-airline-seat-flat-angled.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-airline-seat-flat-angled.fa:before {
  content: "airline_seat_flat_angled";
}

.ui-icon-airline-seat-individual-suite.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-airline-seat-individual-suite.fa:before {
  content: "airline_seat_individual_suite";
}

.ui-icon-airline-seat-legroom-extra.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-airline-seat-legroom-extra.fa:before {
  content: "airline_seat_legroom_extra";
}

.ui-icon-airline-seat-legroom-normal.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-airline-seat-legroom-normal.fa:before {
  content: "airline_seat_legroom_normal";
}

.ui-icon-airline-seat-legroom-reduced.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-airline-seat-legroom-reduced.fa:before {
  content: "airline_seat_legroom_reduced";
}

.ui-icon-airline-seat-recline-extra.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-airline-seat-recline-extra.fa:before {
  content: "airline_seat_recline_extra";
}

.ui-icon-airline-seat-recline-normal.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-airline-seat-recline-normal.fa:before {
  content: "airline_seat_recline_normal";
}

.ui-icon-airplanemode-active.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-airplanemode-active.fa:before {
  content: "airplanemode_active";
}

.ui-icon-airplanemode-inactive.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-airplanemode-inactive.fa:before {
  content: "airplanemode_inactive";
}

.ui-icon-airplay.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-airplay.fa:before {
  content: "airplay";
}

.ui-icon-airport-shuttle.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-airport-shuttle.fa:before {
  content: "airport_shuttle";
}

.ui-icon-alarm.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-alarm.fa:before {
  content: "alarm";
}

.ui-icon-alarm-add.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-alarm-add.fa:before {
  content: "alarm_add";
}

.ui-icon-alarm-off.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-alarm-off.fa:before {
  content: "alarm_off";
}

.ui-icon-alarm-on.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-alarm-on.fa:before {
  content: "alarm_on";
}

.ui-icon-album.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-album.fa:before {
  content: "album";
}

.ui-icon-all-inclusive.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-all-inclusive.fa:before {
  content: "all_inclusive";
}

.ui-icon-all-out.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-all-out.fa:before {
  content: "all_out";
}

.ui-icon-android.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-android.fa:before {
  content: "android";
}

.ui-icon-announcement.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-announcement.fa:before {
  content: "announcement";
}

.ui-icon-apps.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-apps.fa:before {
  content: "apps";
}

.ui-icon-archive.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-archive.fa:before {
  content: "archive";
}

.ui-icon-arrow-back.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-arrow-back.fa:before {
  content: "arrow_back";
}

.ui-icon-arrow-downward.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-arrow-downward.fa:before {
  content: "arrow_downward";
}

.ui-icon-arrow-drop-down.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-arrow-drop-down.fa:before {
  content: "arrow_drop_down";
}

.ui-icon-arrow-drop-down-circle.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-arrow-drop-down-circle.fa:before {
  content: "arrow_drop_down_circle";
}

.ui-icon-arrow-drop-up.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-arrow-drop-up.fa:before {
  content: "arrow_drop_up";
}

.ui-icon-arrow-forward.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-arrow-forward.fa:before {
  content: "arrow_forward";
}

.ui-icon-arrow-upward.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-arrow-upward.fa:before {
  content: "arrow_upward";
}

.ui-icon-art-track.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-art-track.fa:before {
  content: "art_track";
}

.ui-icon-aspect-ratio.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-aspect-ratio.fa:before {
  content: "aspect_ratio";
}

.ui-icon-assessment.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-assessment.fa:before {
  content: "assessment";
}

.ui-icon-assignment.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-assignment.fa:before {
  content: "assignment";
}

.ui-icon-assignment-ind.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-assignment-ind.fa:before {
  content: "assignment_ind";
}

.ui-icon-assignment-late.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-assignment-late.fa:before {
  content: "assignment_late";
}

.ui-icon-assignment-return.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-assignment-return.fa:before {
  content: "assignment_return";
}

.ui-icon-assignment-returned.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-assignment-returned.fa:before {
  content: "assignment_returned";
}

.ui-icon-assignment-turned-in.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-assignment-turned-in.fa:before {
  content: "assignment_turned_in";
}

.ui-icon-assistant.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-assistant.fa:before {
  content: "assistant";
}

.ui-icon-assistant-photo.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-assistant-photo.fa:before {
  content: "assistant_photo";
}

.ui-icon-attach-file.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-attach-file.fa:before {
  content: "attach_file";
}

.ui-icon-attach-money.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-attach-money.fa:before {
  content: "attach_money";
}

.ui-icon-attachment.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-attachment.fa:before {
  content: "attachment";
}

.ui-icon-audiotrack.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-audiotrack.fa:before {
  content: "audiotrack";
}

.ui-icon-autorenew.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-autorenew.fa:before {
  content: "autorenew";
}

.ui-icon-av-timer.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-av-timer.fa:before {
  content: "av_timer";
}

.ui-icon-backspace.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-backspace.fa:before {
  content: "backspace";
}

.ui-icon-backup.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-backup.fa:before {
  content: "backup";
}

.ui-icon-battery-alert.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-battery-alert.fa:before {
  content: "battery_alert";
}

.ui-icon-battery-charging-full.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-battery-charging-full.fa:before {
  content: "battery_charging_full";
}

.ui-icon-battery-full.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-battery-full.fa:before {
  content: "battery_full";
}

.ui-icon-battery-std.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-battery-std.fa:before {
  content: "battery_std";
}

.ui-icon-battery-unknown.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-battery-unknown.fa:before {
  content: "battery_unknown";
}

.ui-icon-beach-access.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-beach-access.fa:before {
  content: "beach_access";
}

.ui-icon-beenhere.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-beenhere.fa:before {
  content: "beenhere";
}

.ui-icon-block.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-block.fa:before {
  content: "block";
}

.ui-icon-bluetooth.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-bluetooth.fa:before {
  content: "bluetooth";
}

.ui-icon-bluetooth-audio.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-bluetooth-audio.fa:before {
  content: "bluetooth_audio";
}

.ui-icon-bluetooth-connected.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-bluetooth-connected.fa:before {
  content: "bluetooth_connected";
}

.ui-icon-bluetooth-disabled.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-bluetooth-disabled.fa:before {
  content: "bluetooth_disabled";
}

.ui-icon-bluetooth-searching.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-bluetooth-searching.fa:before {
  content: "bluetooth_searching";
}

.ui-icon-blur-circular.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-blur-circular.fa:before {
  content: "blur_circular";
}

.ui-icon-blur-linear.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-blur-linear.fa:before {
  content: "blur_linear";
}

.ui-icon-blur-off.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-blur-off.fa:before {
  content: "blur_off";
}

.ui-icon-blur-on.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-blur-on.fa:before {
  content: "blur_on";
}

.ui-icon-book.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-book.fa:before {
  content: "book";
}

.ui-icon-bookmark.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-bookmark.fa:before {
  content: "bookmark";
}

.ui-icon-bookmark-border.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-bookmark-border.fa:before {
  content: "bookmark_border";
}

.ui-icon-border-all.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-border-all.fa:before {
  content: "border_all";
}

.ui-icon-border-bottom.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-border-bottom.fa:before {
  content: "border_bottom";
}

.ui-icon-border-clear.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-border-clear.fa:before {
  content: "border_clear";
}

.ui-icon-border-color.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-border-color.fa:before {
  content: "border_color";
}

.ui-icon-border-horizontal.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-border-horizontal.fa:before {
  content: "border_horizontal";
}

.ui-icon-border-inner.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-border-inner.fa:before {
  content: "border_inner";
}

.ui-icon-border-left.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-border-left.fa:before {
  content: "border_left";
}

.ui-icon-border-outer.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-border-outer.fa:before {
  content: "border_outer";
}

.ui-icon-border-right.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-border-right.fa:before {
  content: "border_right";
}

.ui-icon-border-style.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-border-style.fa:before {
  content: "border_style";
}

.ui-icon-border-top.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-border-top.fa:before {
  content: "border_top";
}

.ui-icon-border-vertical.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-border-vertical.fa:before {
  content: "border_vertical";
}

.ui-icon-branding-watermark.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-branding-watermark.fa:before {
  content: "branding_watermark";
}

.ui-icon-brightness-1.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-brightness-1.fa:before {
  content: "brightness_1";
}

.ui-icon-brightness-2.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-brightness-2.fa:before {
  content: "brightness_2";
}

.ui-icon-brightness-3.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-brightness-3.fa:before {
  content: "brightness_3";
}

.ui-icon-brightness-4.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-brightness-4.fa:before {
  content: "brightness_4";
}

.ui-icon-brightness-5.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-brightness-5.fa:before {
  content: "brightness_5";
}

.ui-icon-brightness-6.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-brightness-6.fa:before {
  content: "brightness_6";
}

.ui-icon-brightness-7.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-brightness-7.fa:before {
  content: "brightness_7";
}

.ui-icon-brightness-auto.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-brightness-auto.fa:before {
  content: "brightness_auto";
}

.ui-icon-brightness-high.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-brightness-high.fa:before {
  content: "brightness_high";
}

.ui-icon-brightness-low.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-brightness-low.fa:before {
  content: "brightness_low";
}

.ui-icon-brightness-medium.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-brightness-medium.fa:before {
  content: "brightness_medium";
}

.ui-icon-broken-image.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-broken-image.fa:before {
  content: "broken_image";
}

.ui-icon-brush.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-brush.fa:before {
  content: "brush";
}

.ui-icon-bubble-chart.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-bubble-chart.fa:before {
  content: "bubble_chart";
}

.ui-icon-bug-report.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-bug-report.fa:before {
  content: "bug_report";
}

.ui-icon-build.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-build.fa:before {
  content: "build";
}

.ui-icon-burst-mode.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-burst-mode.fa:before {
  content: "burst_mode";
}

.ui-icon-business.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-business.fa:before {
  content: "business";
}

.ui-icon-business-center.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-business-center.fa:before {
  content: "business_center";
}

.ui-icon-cached.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-cached.fa:before {
  content: "cached";
}

.ui-icon-cake.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-cake.fa:before {
  content: "cake";
}

.ui-icon-call.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-call.fa:before {
  content: "call";
}

.ui-icon-call-end.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-call-end.fa:before {
  content: "call_end";
}

.ui-icon-call-made.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-call-made.fa:before {
  content: "call_made";
}

.ui-icon-call-merge.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-call-merge.fa:before {
  content: "call_merge";
}

.ui-icon-call-missed.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-call-missed.fa:before {
  content: "call_missed";
}

.ui-icon-call-missed-outgoing.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-call-missed-outgoing.fa:before {
  content: "call_missed_outgoing";
}

.ui-icon-call-received.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-call-received.fa:before {
  content: "call_received";
}

.ui-icon-call-split.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-call-split.fa:before {
  content: "call_split";
}

.ui-icon-call-to-action.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-call-to-action.fa:before {
  content: "call_to_action";
}

.ui-icon-camera.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-camera.fa:before {
  content: "camera";
}

.ui-icon-camera-alt.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-camera-alt.fa:before {
  content: "camera_alt";
}

.ui-icon-camera-enhance.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-camera-enhance.fa:before {
  content: "camera_enhance";
}

.ui-icon-camera-front.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-camera-front.fa:before {
  content: "camera_front";
}

.ui-icon-camera-rear.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-camera-rear.fa:before {
  content: "camera_rear";
}

.ui-icon-camera-roll.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-camera-roll.fa:before {
  content: "camera_roll";
}

.ui-icon-cancel.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-cancel.fa:before {
  content: "cancel";
}

.ui-icon-card-giftcard.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-card-giftcard.fa:before {
  content: "card_giftcard";
}

.ui-icon-card-membership.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-card-membership.fa:before {
  content: "card_membership";
}

.ui-icon-card-travel.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-card-travel.fa:before {
  content: "card_travel";
}

.ui-icon-casino.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-casino.fa:before {
  content: "casino";
}

.ui-icon-cast.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-cast.fa:before {
  content: "cast";
}

.ui-icon-cast-connected.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-cast-connected.fa:before {
  content: "cast_connected";
}

.ui-icon-center-focus-strong.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-center-focus-strong.fa:before {
  content: "center_focus_strong";
}

.ui-icon-center-focus-weak.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-center-focus-weak.fa:before {
  content: "center_focus_weak";
}

.ui-icon-change-history.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-change-history.fa:before {
  content: "change_history";
}

.ui-icon-chat.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-chat.fa:before {
  content: "chat";
}

.ui-icon-chat-bubble.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-chat-bubble.fa:before {
  content: "chat_bubble";
}

.ui-icon-chat-bubble-outline.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-chat-bubble-outline.fa:before {
  content: "chat_bubble_outline";
}

.ui-icon-check.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-check.fa:before {
  content: "check";
}

.ui-icon-check-box.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-check-box.fa:before {
  content: "check_box";
}

.ui-icon-check-box-outline-blank.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-check-box-outline-blank.fa:before {
  content: "check_box_outline_blank";
}

.ui-icon-check-circle.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-check-circle.fa:before {
  content: "check_circle";
}

.ui-icon-chevron-left.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-chevron-left.fa:before {
  content: "chevron_left";
}

.ui-icon-chevron-right.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-chevron-right.fa:before {
  content: "chevron_right";
}

.ui-icon-child-care.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-child-care.fa:before {
  content: "child_care";
}

.ui-icon-child-friendly.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-child-friendly.fa:before {
  content: "child_friendly";
}

.ui-icon-chrome-reader-mode.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-chrome-reader-mode.fa:before {
  content: "chrome_reader_mode";
}

.ui-icon-class.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-class.fa:before {
  content: "class";
}

.ui-icon-clear.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-clear.fa:before {
  content: "clear";
}

.ui-icon-clear-all.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-clear-all.fa:before {
  content: "clear_all";
}

.ui-icon-close.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-close.fa:before {
  content: "close";
}

.ui-icon-closed-caption.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-closed-caption.fa:before {
  content: "closed_caption";
}

.ui-icon-cloud.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-cloud.fa:before {
  content: "cloud";
}

.ui-icon-cloud-circle.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-cloud-circle.fa:before {
  content: "cloud_circle";
}

.ui-icon-cloud-done.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-cloud-done.fa:before {
  content: "cloud_done";
}

.ui-icon-cloud-download.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-cloud-download.fa:before {
  content: "cloud_download";
}

.ui-icon-cloud-off.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-cloud-off.fa:before {
  content: "cloud_off";
}

.ui-icon-cloud-queue.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-cloud-queue.fa:before {
  content: "cloud_queue";
}

.ui-icon-cloud-upload.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-cloud-upload.fa:before {
  content: "cloud_upload";
}

.ui-icon-code.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-code.fa:before {
  content: "code";
}

.ui-icon-collections.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-collections.fa:before {
  content: "collections";
}

.ui-icon-collections-bookmark.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-collections-bookmark.fa:before {
  content: "collections_bookmark";
}

.ui-icon-color-lens.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-color-lens.fa:before {
  content: "color_lens";
}

.ui-icon-colorize.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-colorize.fa:before {
  content: "colorize";
}

.ui-icon-comment.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-comment.fa:before {
  content: "comment";
}

.ui-icon-compare.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-compare.fa:before {
  content: "compare";
}

.ui-icon-compare-arrows.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-compare-arrows.fa:before {
  content: "compare_arrows";
}

.ui-icon-computer.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-computer.fa:before {
  content: "computer";
}

.ui-icon-confirmation-number.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-confirmation-number.fa:before {
  content: "confirmation_number";
}

.ui-icon-contact-mail.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-contact-mail.fa:before {
  content: "contact_mail";
}

.ui-icon-contact-phone.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-contact-phone.fa:before {
  content: "contact_phone";
}

.ui-icon-contacts.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-contacts.fa:before {
  content: "contacts";
}

.ui-icon-content-copy.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-content-copy.fa:before {
  content: "content_copy";
}

.ui-icon-content-cut.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-content-cut.fa:before {
  content: "content_cut";
}

.ui-icon-content-paste.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-content-paste.fa:before {
  content: "content_paste";
}

.ui-icon-control-point.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-control-point.fa:before {
  content: "control_point";
}

.ui-icon-control-point-duplicate.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-control-point-duplicate.fa:before {
  content: "control_point_duplicate";
}

.ui-icon-copyright.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-copyright.fa:before {
  content: "copyright";
}

.ui-icon-create.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-create.fa:before {
  content: "create";
}

.ui-icon-create-new-folder.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-create-new-folder.fa:before {
  content: "create_new_folder";
}

.ui-icon-credit-card.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-credit-card.fa:before {
  content: "credit_card";
}

.ui-icon-crop.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-crop.fa:before {
  content: "crop";
}

.ui-icon-crop-16-9.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-crop-16-9.fa:before {
  content: "crop_16_9";
}

.ui-icon-crop-3-2.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-crop-3-2.fa:before {
  content: "crop_3_2";
}

.ui-icon-crop-5-4.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-crop-5-4.fa:before {
  content: "crop_5_4";
}

.ui-icon-crop-7-5.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-crop-7-5.fa:before {
  content: "crop_7_5";
}

.ui-icon-crop-din.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-crop-din.fa:before {
  content: "crop_din";
}

.ui-icon-crop-free.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-crop-free.fa:before {
  content: "crop_free";
}

.ui-icon-crop-landscape.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-crop-landscape.fa:before {
  content: "crop_landscape";
}

.ui-icon-crop-original.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-crop-original.fa:before {
  content: "crop_original";
}

.ui-icon-crop-portrait.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-crop-portrait.fa:before {
  content: "crop_portrait";
}

.ui-icon-crop-rotate.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-crop-rotate.fa:before {
  content: "crop_rotate";
}

.ui-icon-crop-square.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-crop-square.fa:before {
  content: "crop_square";
}

.ui-icon-dashboard.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-dashboard.fa:before {
  content: "dashboard";
}

.ui-icon-data-usage.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-data-usage.fa:before {
  content: "data_usage";
}

.ui-icon-date-range.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-date-range.fa:before {
  content: "date_range";
}

.ui-icon-dehaze.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-dehaze.fa:before {
  content: "dehaze";
}

.ui-icon-delete.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-delete.fa:before {
  content: "delete";
}

.ui-icon-delete-forever.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-delete-forever.fa:before {
  content: "delete_forever";
}

.ui-icon-delete-sweep.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-delete-sweep.fa:before {
  content: "delete_sweep";
}

.ui-icon-description.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-description.fa:before {
  content: "description";
}

.ui-icon-desktop-mac.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-desktop-mac.fa:before {
  content: "desktop_mac";
}

.ui-icon-desktop-windows.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-desktop-windows.fa:before {
  content: "desktop_windows";
}

.ui-icon-details.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-details.fa:before {
  content: "details";
}

.ui-icon-developer-board.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-developer-board.fa:before {
  content: "developer_board";
}

.ui-icon-developer-mode.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-developer-mode.fa:before {
  content: "developer_mode";
}

.ui-icon-device-hub.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-device-hub.fa:before {
  content: "device_hub";
}

.ui-icon-devices.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-devices.fa:before {
  content: "devices";
}

.ui-icon-devices-other.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-devices-other.fa:before {
  content: "devices_other";
}

.ui-icon-dialer-sip.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-dialer-sip.fa:before {
  content: "dialer_sip";
}

.ui-icon-dialpad.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-dialpad.fa:before {
  content: "dialpad";
}

.ui-icon-directions.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-directions.fa:before {
  content: "directions";
}

.ui-icon-directions-bike.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-directions-bike.fa:before {
  content: "directions_bike";
}

.ui-icon-directions-boat.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-directions-boat.fa:before {
  content: "directions_boat";
}

.ui-icon-directions-bus.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-directions-bus.fa:before {
  content: "directions_bus";
}

.ui-icon-directions-car.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-directions-car.fa:before {
  content: "directions_car";
}

.ui-icon-directions-railway.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-directions-railway.fa:before {
  content: "directions_railway";
}

.ui-icon-directions-run.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-directions-run.fa:before {
  content: "directions_run";
}

.ui-icon-directions-subway.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-directions-subway.fa:before {
  content: "directions_subway";
}

.ui-icon-directions-transit.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-directions-transit.fa:before {
  content: "directions_transit";
}

.ui-icon-directions-walk.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-directions-walk.fa:before {
  content: "directions_walk";
}

.ui-icon-disc-full.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-disc-full.fa:before {
  content: "disc_full";
}

.ui-icon-dns.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-dns.fa:before {
  content: "dns";
}

.ui-icon-do-not-disturb.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-do-not-disturb.fa:before {
  content: "do_not_disturb";
}

.ui-icon-do-not-disturb-alt.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-do-not-disturb-alt.fa:before {
  content: "do_not_disturb_alt";
}

.ui-icon-do-not-disturb-off.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-do-not-disturb-off.fa:before {
  content: "do_not_disturb_off";
}

.ui-icon-do-not-disturb-on.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-do-not-disturb-on.fa:before {
  content: "do_not_disturb_on";
}

.ui-icon-dock.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-dock.fa:before {
  content: "dock";
}

.ui-icon-domain.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-domain.fa:before {
  content: "domain";
}

.ui-icon-done.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-done.fa:before {
  content: "done";
}

.ui-icon-done-all.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-done-all.fa:before {
  content: "done_all";
}

.ui-icon-donut-large.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-donut-large.fa:before {
  content: "donut_large";
}

.ui-icon-donut-small.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-donut-small.fa:before {
  content: "donut_small";
}

.ui-icon-drafts.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-drafts.fa:before {
  content: "drafts";
}

.ui-icon-drag-handle.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-drag-handle.fa:before {
  content: "drag_handle";
}

.ui-icon-drive-eta.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-drive-eta.fa:before {
  content: "drive_eta";
}

.ui-icon-dvr.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-dvr.fa:before {
  content: "dvr";
}

.ui-icon-edit.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-edit.fa:before {
  content: "edit";
}

.ui-icon-edit-location.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-edit-location.fa:before {
  content: "edit_location";
}

.ui-icon-eject.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-eject.fa:before {
  content: "eject";
}

.ui-icon-email.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-email.fa:before {
  content: "email";
}

.ui-icon-enhanced-encryption.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-enhanced-encryption.fa:before {
  content: "enhanced_encryption";
}

.ui-icon-equalizer.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-equalizer.fa:before {
  content: "equalizer";
}

.ui-icon-error.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-error.fa:before {
  content: "error";
}

.ui-icon-error-outline.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-error-outline.fa:before {
  content: "error_outline";
}

.ui-icon-euro-symbol.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-euro-symbol.fa:before {
  content: "euro_symbol";
}

.ui-icon-ev-station.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-ev-station.fa:before {
  content: "ev_station";
}

.ui-icon-event.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-event.fa:before {
  content: "event";
}

.ui-icon-event-available.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-event-available.fa:before {
  content: "event_available";
}

.ui-icon-event-busy.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-event-busy.fa:before {
  content: "event_busy";
}

.ui-icon-event-note.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-event-note.fa:before {
  content: "event_note";
}

.ui-icon-event-seat.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-event-seat.fa:before {
  content: "event_seat";
}

.ui-icon-exit-to-app.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-exit-to-app.fa:before {
  content: "exit_to_app";
}

.ui-icon-expand-less.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-expand-less.fa:before {
  content: "expand_less";
}

.ui-icon-expand-more.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-expand-more.fa:before {
  content: "expand_more";
}

.ui-icon-explicit.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-explicit.fa:before {
  content: "explicit";
}

.ui-icon-explore.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-explore.fa:before {
  content: "explore";
}

.ui-icon-exposure.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-exposure.fa:before {
  content: "exposure";
}

.ui-icon-exposure-neg-1.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-exposure-neg-1.fa:before {
  content: "exposure_neg_1";
}

.ui-icon-exposure-neg-2.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-exposure-neg-2.fa:before {
  content: "exposure_neg_2";
}

.ui-icon-exposure-plus-1.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-exposure-plus-1.fa:before {
  content: "exposure_plus_1";
}

.ui-icon-exposure-plus-2.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-exposure-plus-2.fa:before {
  content: "exposure_plus_2";
}

.ui-icon-exposure-zero.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-exposure-zero.fa:before {
  content: "exposure_zero";
}

.ui-icon-extension.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-extension.fa:before {
  content: "extension";
}

.ui-icon-face.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-face.fa:before {
  content: "face";
}

.ui-icon-fast-forward.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-fast-forward.fa:before {
  content: "fast_forward";
}

.ui-icon-fast-rewind.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-fast-rewind.fa:before {
  content: "fast_rewind";
}

.ui-icon-favorite.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-favorite.fa:before {
  content: "favorite";
}

.ui-icon-favorite-border.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-favorite-border.fa:before {
  content: "favorite_border";
}

.ui-icon-featured-play-list.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-featured-play-list.fa:before {
  content: "featured_play_list";
}

.ui-icon-featured-video.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-featured-video.fa:before {
  content: "featured_video";
}

.ui-icon-feedback.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-feedback.fa:before {
  content: "feedback";
}

.ui-icon-fiber-dvr.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-fiber-dvr.fa:before {
  content: "fiber_dvr";
}

.ui-icon-fiber-manual-record.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-fiber-manual-record.fa:before {
  content: "fiber_manual_record";
}

.ui-icon-fiber-new.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-fiber-new.fa:before {
  content: "fiber_new";
}

.ui-icon-fiber-pin.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-fiber-pin.fa:before {
  content: "fiber_pin";
}

.ui-icon-fiber-smart-record.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-fiber-smart-record.fa:before {
  content: "fiber_smart_record";
}

.ui-icon-file-download.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-file-download.fa:before {
  content: "file_download";
}

.ui-icon-file-upload.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-file-upload.fa:before {
  content: "file_upload";
}

.ui-icon-filter.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-filter.fa:before {
  content: "filter";
}

.ui-icon-filter-1.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-filter-1.fa:before {
  content: "filter_1";
}

.ui-icon-filter-2.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-filter-2.fa:before {
  content: "filter_2";
}

.ui-icon-filter-3.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-filter-3.fa:before {
  content: "filter_3";
}

.ui-icon-filter-4.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-filter-4.fa:before {
  content: "filter_4";
}

.ui-icon-filter-5.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-filter-5.fa:before {
  content: "filter_5";
}

.ui-icon-filter-6.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-filter-6.fa:before {
  content: "filter_6";
}

.ui-icon-filter-7.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-filter-7.fa:before {
  content: "filter_7";
}

.ui-icon-filter-8.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-filter-8.fa:before {
  content: "filter_8";
}

.ui-icon-filter-9.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-filter-9.fa:before {
  content: "filter_9";
}

.ui-icon-filter-9-plus.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-filter-9-plus.fa:before {
  content: "filter_9_plus";
}

.ui-icon-filter-b-and-w.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-filter-b-and-w.fa:before {
  content: "filter_b_and_w";
}

.ui-icon-filter-center-focus.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-filter-center-focus.fa:before {
  content: "filter_center_focus";
}

.ui-icon-filter-drama.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-filter-drama.fa:before {
  content: "filter_drama";
}

.ui-icon-filter-frames.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-filter-frames.fa:before {
  content: "filter_frames";
}

.ui-icon-filter-hdr.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-filter-hdr.fa:before {
  content: "filter_hdr";
}

.ui-icon-filter-list.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-filter-list.fa:before {
  content: "filter_list";
}

.ui-icon-filter-none.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-filter-none.fa:before {
  content: "filter_none";
}

.ui-icon-filter-tilt-shift.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-filter-tilt-shift.fa:before {
  content: "filter_tilt_shift";
}

.ui-icon-filter-vintage.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-filter-vintage.fa:before {
  content: "filter_vintage";
}

.ui-icon-find-in-page.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-find-in-page.fa:before {
  content: "find_in_page";
}

.ui-icon-find-replace.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-find-replace.fa:before {
  content: "find_replace";
}

.ui-icon-fingerprint.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-fingerprint.fa:before {
  content: "fingerprint";
}

.ui-icon-first-page.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-first-page.fa:before {
  content: "first_page";
}

.ui-icon-fitness-center.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-fitness-center.fa:before {
  content: "fitness_center";
}

.ui-icon-flag.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-flag.fa:before {
  content: "flag";
}

.ui-icon-flare.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-flare.fa:before {
  content: "flare";
}

.ui-icon-flash-auto.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-flash-auto.fa:before {
  content: "flash_auto";
}

.ui-icon-flash-off.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-flash-off.fa:before {
  content: "flash_off";
}

.ui-icon-flash-on.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-flash-on.fa:before {
  content: "flash_on";
}

.ui-icon-flight.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-flight.fa:before {
  content: "flight";
}

.ui-icon-flight-land.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-flight-land.fa:before {
  content: "flight_land";
}

.ui-icon-flight-takeoff.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-flight-takeoff.fa:before {
  content: "flight_takeoff";
}

.ui-icon-flip.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-flip.fa:before {
  content: "flip";
}

.ui-icon-flip-to-back.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-flip-to-back.fa:before {
  content: "flip_to_back";
}

.ui-icon-flip-to-front.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-flip-to-front.fa:before {
  content: "flip_to_front";
}

.ui-icon-folder.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-folder.fa:before {
  content: "folder";
}

.ui-icon-folder-open.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-folder-open.fa:before {
  content: "folder_open";
}

.ui-icon-folder-shared.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-folder-shared.fa:before {
  content: "folder_shared";
}

.ui-icon-folder-special.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-folder-special.fa:before {
  content: "folder_special";
}

.ui-icon-font-download.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-font-download.fa:before {
  content: "font_download";
}

.ui-icon-format-align-center.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-format-align-center.fa:before {
  content: "format_align_center";
}

.ui-icon-format-align-justify.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-format-align-justify.fa:before {
  content: "format_align_justify";
}

.ui-icon-format-align-left.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-format-align-left.fa:before {
  content: "format_align_left";
}

.ui-icon-format-align-right.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-format-align-right.fa:before {
  content: "format_align_right";
}

.ui-icon-format-bold.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-format-bold.fa:before {
  content: "format_bold";
}

.ui-icon-format-clear.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-format-clear.fa:before {
  content: "format_clear";
}

.ui-icon-format-color-fill.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-format-color-fill.fa:before {
  content: "format_color_fill";
}

.ui-icon-format-color-reset.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-format-color-reset.fa:before {
  content: "format_color_reset";
}

.ui-icon-format-color-text.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-format-color-text.fa:before {
  content: "format_color_text";
}

.ui-icon-format-indent-decrease.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-format-indent-decrease.fa:before {
  content: "format_indent_decrease";
}

.ui-icon-format-indent-increase.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-format-indent-increase.fa:before {
  content: "format_indent_increase";
}

.ui-icon-format-italic.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-format-italic.fa:before {
  content: "format_italic";
}

.ui-icon-format-line-spacing.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-format-line-spacing.fa:before {
  content: "format_line_spacing";
}

.ui-icon-format-list-bulleted.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-format-list-bulleted.fa:before {
  content: "format_list_bulleted";
}

.ui-icon-format-list-numbered.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-format-list-numbered.fa:before {
  content: "format_list_numbered";
}

.ui-icon-format-paint.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-format-paint.fa:before {
  content: "format_paint";
}

.ui-icon-format-quote.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-format-quote.fa:before {
  content: "format_quote";
}

.ui-icon-format-shapes.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-format-shapes.fa:before {
  content: "format_shapes";
}

.ui-icon-format-size.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-format-size.fa:before {
  content: "format_size";
}

.ui-icon-format-strikethrough.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-format-strikethrough.fa:before {
  content: "format_strikethrough";
}

.ui-icon-format-textdirection-l-to-r.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-format-textdirection-l-to-r.fa:before {
  content: "format_textdirection_l_to_r";
}

.ui-icon-format-textdirection-r-to-l.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-format-textdirection-r-to-l.fa:before {
  content: "format_textdirection_r_to_l";
}

.ui-icon-format-underlined.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-format-underlined.fa:before {
  content: "format_underlined";
}

.ui-icon-forum.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-forum.fa:before {
  content: "forum";
}

.ui-icon-forward.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-forward.fa:before {
  content: "forward";
}

.ui-icon-forward-10.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-forward-10.fa:before {
  content: "forward_10";
}

.ui-icon-forward-30.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-forward-30.fa:before {
  content: "forward_30";
}

.ui-icon-forward-5.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-forward-5.fa:before {
  content: "forward_5";
}

.ui-icon-free-breakfast.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-free-breakfast.fa:before {
  content: "free_breakfast";
}

.ui-icon-fullscreen.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-fullscreen.fa:before {
  content: "fullscreen";
}

.ui-icon-fullscreen-exit.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-fullscreen-exit.fa:before {
  content: "fullscreen_exit";
}

.ui-icon-functions.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-functions.fa:before {
  content: "functions";
}

.ui-icon-g-translate.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-g-translate.fa:before {
  content: "g_translate";
}

.ui-icon-gamepad.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-gamepad.fa:before {
  content: "gamepad";
}

.ui-icon-games.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-games.fa:before {
  content: "games";
}

.ui-icon-gavel.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-gavel.fa:before {
  content: "gavel";
}

.ui-icon-gesture.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-gesture.fa:before {
  content: "gesture";
}

.ui-icon-get-app.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-get-app.fa:before {
  content: "get_app";
}

.ui-icon-gif.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-gif.fa:before {
  content: "gif";
}

.ui-icon-golf-course.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-golf-course.fa:before {
  content: "golf_course";
}

.ui-icon-gps-fixed.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-gps-fixed.fa:before {
  content: "gps_fixed";
}

.ui-icon-gps-not-fixed.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-gps-not-fixed.fa:before {
  content: "gps_not_fixed";
}

.ui-icon-gps-off.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-gps-off.fa:before {
  content: "gps_off";
}

.ui-icon-grade.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-grade.fa:before {
  content: "grade";
}

.ui-icon-gradient.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-gradient.fa:before {
  content: "gradient";
}

.ui-icon-grain.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-grain.fa:before {
  content: "grain";
}

.ui-icon-graphic-eq.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-graphic-eq.fa:before {
  content: "graphic_eq";
}

.ui-icon-grid-off.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-grid-off.fa:before {
  content: "grid_off";
}

.ui-icon-grid-on.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-grid-on.fa:before {
  content: "grid_on";
}

.ui-icon-group.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-group.fa:before {
  content: "group";
}

.ui-icon-group-add.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-group-add.fa:before {
  content: "group_add";
}

.ui-icon-group-work.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-group-work.fa:before {
  content: "group_work";
}

.ui-icon-hd.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-hd.fa:before {
  content: "hd";
}

.ui-icon-hdr-off.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-hdr-off.fa:before {
  content: "hdr_off";
}

.ui-icon-hdr-on.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-hdr-on.fa:before {
  content: "hdr_on";
}

.ui-icon-hdr-strong.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-hdr-strong.fa:before {
  content: "hdr_strong";
}

.ui-icon-hdr-weak.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-hdr-weak.fa:before {
  content: "hdr_weak";
}

.ui-icon-headset.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-headset.fa:before {
  content: "headset";
}

.ui-icon-headset-mic.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-headset-mic.fa:before {
  content: "headset_mic";
}

.ui-icon-healing.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-healing.fa:before {
  content: "healing";
}

.ui-icon-hearing.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-hearing.fa:before {
  content: "hearing";
}

.ui-icon-help.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-help.fa:before {
  content: "help";
}

.ui-icon-help-outline.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-help-outline.fa:before {
  content: "help_outline";
}

.ui-icon-high-quality.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-high-quality.fa:before {
  content: "high_quality";
}

.ui-icon-highlight.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-highlight.fa:before {
  content: "highlight";
}

.ui-icon-highlight-off.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-highlight-off.fa:before {
  content: "highlight_off";
}

.ui-icon-history.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-history.fa:before {
  content: "history";
}

.ui-icon-home.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-home.fa:before {
  content: "home";
}

.ui-icon-hot-tub.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-hot-tub.fa:before {
  content: "hot_tub";
}

.ui-icon-hotel.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-hotel.fa:before {
  content: "hotel";
}

.ui-icon-hourglass-empty.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-hourglass-empty.fa:before {
  content: "hourglass_empty";
}

.ui-icon-hourglass-full.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-hourglass-full.fa:before {
  content: "hourglass_full";
}

.ui-icon-http.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-http.fa:before {
  content: "http";
}

.ui-icon-https.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-https.fa:before {
  content: "https";
}

.ui-icon-image.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-image.fa:before {
  content: "image";
}

.ui-icon-image-aspect-ratio.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-image-aspect-ratio.fa:before {
  content: "image_aspect_ratio";
}

.ui-icon-import-contacts.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-import-contacts.fa:before {
  content: "import_contacts";
}

.ui-icon-import-export.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-import-export.fa:before {
  content: "import_export";
}

.ui-icon-important-devices.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-important-devices.fa:before {
  content: "important_devices";
}

.ui-icon-inbox.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-inbox.fa:before {
  content: "inbox";
}

.ui-icon-indeterminate-check-box.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-indeterminate-check-box.fa:before {
  content: "indeterminate_check_box";
}

.ui-icon-info.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-info.fa:before {
  content: "info";
}

.ui-icon-info-outline.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-info-outline.fa:before {
  content: "info_outline";
}

.ui-icon-input.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-input.fa:before {
  content: "input";
}

.ui-icon-insert-chart.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-insert-chart.fa:before {
  content: "insert_chart";
}

.ui-icon-insert-comment.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-insert-comment.fa:before {
  content: "insert_comment";
}

.ui-icon-insert-drive-file.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-insert-drive-file.fa:before {
  content: "insert_drive_file";
}

.ui-icon-insert-emoticon.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-insert-emoticon.fa:before {
  content: "insert_emoticon";
}

.ui-icon-insert-invitation.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-insert-invitation.fa:before {
  content: "insert_invitation";
}

.ui-icon-insert-link.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-insert-link.fa:before {
  content: "insert_link";
}

.ui-icon-insert-photo.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-insert-photo.fa:before {
  content: "insert_photo";
}

.ui-icon-invert-colors.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-invert-colors.fa:before {
  content: "invert_colors";
}

.ui-icon-invert-colors-off.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-invert-colors-off.fa:before {
  content: "invert_colors_off";
}

.ui-icon-iso.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-iso.fa:before {
  content: "iso";
}

.ui-icon-keyboard.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-keyboard.fa:before {
  content: "keyboard";
}

.ui-icon-keyboard-arrow-down.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-keyboard-arrow-down.fa:before {
  content: "keyboard_arrow_down";
}

.ui-icon-keyboard-arrow-left.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-keyboard-arrow-left.fa:before {
  content: "keyboard_arrow_left";
}

.ui-icon-keyboard-arrow-right.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-keyboard-arrow-right.fa:before {
  content: "keyboard_arrow_right";
}

.ui-icon-keyboard-arrow-up.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-keyboard-arrow-up.fa:before {
  content: "keyboard_arrow_up";
}

.ui-icon-keyboard-backspace.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-keyboard-backspace.fa:before {
  content: "keyboard_backspace";
}

.ui-icon-keyboard-capslock.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-keyboard-capslock.fa:before {
  content: "keyboard_capslock";
}

.ui-icon-keyboard-hide.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-keyboard-hide.fa:before {
  content: "keyboard_hide";
}

.ui-icon-keyboard-return.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-keyboard-return.fa:before {
  content: "keyboard_return";
}

.ui-icon-keyboard-tab.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-keyboard-tab.fa:before {
  content: "keyboard_tab";
}

.ui-icon-keyboard-voice.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-keyboard-voice.fa:before {
  content: "keyboard_voice";
}

.ui-icon-kitchen.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-kitchen.fa:before {
  content: "kitchen";
}

.ui-icon-label.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-label.fa:before {
  content: "label";
}

.ui-icon-label-outline.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-label-outline.fa:before {
  content: "label_outline";
}

.ui-icon-landscape.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-landscape.fa:before {
  content: "landscape";
}

.ui-icon-language.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-language.fa:before {
  content: "language";
}

.ui-icon-laptop.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-laptop.fa:before {
  content: "laptop";
}

.ui-icon-laptop-chromebook.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-laptop-chromebook.fa:before {
  content: "laptop_chromebook";
}

.ui-icon-laptop-mac.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-laptop-mac.fa:before {
  content: "laptop_mac";
}

.ui-icon-laptop-windows.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-laptop-windows.fa:before {
  content: "laptop_windows";
}

.ui-icon-last-page.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-last-page.fa:before {
  /* content: "last_page"; */
}

.ui-icon-launch.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-launch.fa:before {
  content: "launch";
}

.ui-icon-layers.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-layers.fa:before {
  content: "layers";
}

.ui-icon-layers-clear.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-layers-clear.fa:before {
  content: "layers_clear";
}

.ui-icon-leak-add.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-leak-add.fa:before {
  content: "leak_add";
}

.ui-icon-leak-remove.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-leak-remove.fa:before {
  content: "leak_remove";
}

.ui-icon-lens.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-lens.fa:before {
  content: "lens";
}

.ui-icon-library-add.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-library-add.fa:before {
  content: "library_add";
}

.ui-icon-library-books.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-library-books.fa:before {
  content: "library_books";
}

.ui-icon-library-music.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-library-music.fa:before {
  content: "library_music";
}

.ui-icon-lightbulb-outline.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-lightbulb-outline.fa:before {
  content: "lightbulb_outline";
}

.ui-icon-line-style.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-line-style.fa:before {
  content: "line_style";
}

.ui-icon-line-weight.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-line-weight.fa:before {
  content: "line_weight";
}

.ui-icon-linear-scale.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-linear-scale.fa:before {
  content: "linear_scale";
}

.ui-icon-link.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-link.fa:before {
  content: "link";
}

.ui-icon-linked-camera.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-linked-camera.fa:before {
  content: "linked_camera";
}

.ui-icon-list.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-list.fa:before {
  content: "list";
}

.ui-icon-live-help.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-live-help.fa:before {
  content: "live_help";
}

.ui-icon-live-tv.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-live-tv.fa:before {
  content: "live_tv";
}

.ui-icon-local-activity.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-local-activity.fa:before {
  content: "local_activity";
}

.ui-icon-local-airport.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-local-airport.fa:before {
  content: "local_airport";
}

.ui-icon-local-atm.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-local-atm.fa:before {
  content: "local_atm";
}

.ui-icon-local-bar.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-local-bar.fa:before {
  content: "local_bar";
}

.ui-icon-local-cafe.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-local-cafe.fa:before {
  content: "local_cafe";
}

.ui-icon-local-car-wash.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-local-car-wash.fa:before {
  content: "local_car_wash";
}

.ui-icon-local-convenience-store.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-local-convenience-store.fa:before {
  content: "local_convenience_store";
}

.ui-icon-local-dining.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-local-dining.fa:before {
  content: "local_dining";
}

.ui-icon-local-drink.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-local-drink.fa:before {
  content: "local_drink";
}

.ui-icon-local-florist.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-local-florist.fa:before {
  content: "local_florist";
}

.ui-icon-local-gas-station.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-local-gas-station.fa:before {
  content: "local_gas_station";
}

.ui-icon-local-grocery-store.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-local-grocery-store.fa:before {
  content: "local_grocery_store";
}

.ui-icon-local-hospital.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-local-hospital.fa:before {
  content: "local_hospital";
}

.ui-icon-local-hotel.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-local-hotel.fa:before {
  content: "local_hotel";
}

.ui-icon-local-laundry-service.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-local-laundry-service.fa:before {
  content: "local_laundry_service";
}

.ui-icon-local-library.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-local-library.fa:before {
  content: "local_library";
}

.ui-icon-local-mall.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-local-mall.fa:before {
  content: "local_mall";
}

.ui-icon-local-movies.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-local-movies.fa:before {
  content: "local_movies";
}

.ui-icon-local-offer.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-local-offer.fa:before {
  content: "local_offer";
}

.ui-icon-local-parking.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-local-parking.fa:before {
  content: "local_parking";
}

.ui-icon-local-pharmacy.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-local-pharmacy.fa:before {
  content: "local_pharmacy";
}

.ui-icon-local-phone.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-local-phone.fa:before {
  content: "local_phone";
}

.ui-icon-local-pizza.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-local-pizza.fa:before {
  content: "local_pizza";
}

.ui-icon-local-play.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-local-play.fa:before {
  content: "local_play";
}

.ui-icon-local-post-office.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-local-post-office.fa:before {
  content: "local_post_office";
}

.ui-icon-local-printshop.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-local-printshop.fa:before {
  content: "local_printshop";
}

.ui-icon-local-see.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-local-see.fa:before {
  content: "local_see";
}

.ui-icon-local-shipping.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-local-shipping.fa:before {
  content: "local_shipping";
}

.ui-icon-local-taxi.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-local-taxi.fa:before {
  content: "local_taxi";
}

.ui-icon-location-city.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-location-city.fa:before {
  content: "location_city";
}

.ui-icon-location-disabled.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-location-disabled.fa:before {
  content: "location_disabled";
}

.ui-icon-location-off.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-location-off.fa:before {
  content: "location_off";
}

.ui-icon-location-on.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-location-on.fa:before {
  content: "location_on";
}

.ui-icon-location-searching.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-location-searching.fa:before {
  content: "location_searching";
}

.ui-icon-lock.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-lock.fa:before {
  content: "lock";
}

.ui-icon-lock-open.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-lock-open.fa:before {
  content: "lock_open";
}

.ui-icon-lock-outline.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-lock-outline.fa:before {
  content: "lock_outline";
}

.ui-icon-looks.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-looks.fa:before {
  content: "looks";
}

.ui-icon-looks-3.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-looks-3.fa:before {
  content: "looks_3";
}

.ui-icon-looks-4.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-looks-4.fa:before {
  content: "looks_4";
}

.ui-icon-looks-5.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-looks-5.fa:before {
  content: "looks_5";
}

.ui-icon-looks-6.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-looks-6.fa:before {
  content: "looks_6";
}

.ui-icon-looks-one.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-looks-one.fa:before {
  content: "looks_one";
}

.ui-icon-looks-two.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-looks-two.fa:before {
  content: "looks_two";
}

.ui-icon-loop.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-loop.fa:before {
  content: "loop";
}

.ui-icon-loupe.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-loupe.fa:before {
  content: "loupe";
}

.ui-icon-low-priority.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-low-priority.fa:before {
  content: "low_priority";
}

.ui-icon-loyalty.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-loyalty.fa:before {
  content: "loyalty";
}

.ui-icon-mail.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-mail.fa:before {
  content: "mail";
}

.ui-icon-mail-outline.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-mail-outline.fa:before {
  content: "mail_outline";
}

.ui-icon-map.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-map.fa:before {
  content: "map";
}

.ui-icon-markunread.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-markunread.fa:before {
  content: "markunread";
}

.ui-icon-markunread-mailbox.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-markunread-mailbox.fa:before {
  content: "markunread_mailbox";
}

.ui-icon-memory.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-memory.fa:before {
  content: "memory";
}

.ui-icon-menu.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-menu.fa:before {
  content: "menu";
}

.ui-icon-merge-type.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-merge-type.fa:before {
  content: "merge_type";
}

.ui-icon-message.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-message.fa:before {
  content: "message";
}

.ui-icon-mic.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-mic.fa:before {
  content: "mic";
}

.ui-icon-mic-none.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-mic-none.fa:before {
  content: "mic_none";
}

.ui-icon-mic-off.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-mic-off.fa:before {
  content: "mic_off";
}

.ui-icon-mms.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-mms.fa:before {
  content: "mms";
}

.ui-icon-mode-comment.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-mode-comment.fa:before {
  content: "mode_comment";
}

.ui-icon-mode-edit.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-mode-edit.fa:before {
  content: "mode_edit";
}

.ui-icon-monetization-on.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-monetization-on.fa:before {
  content: "monetization_on";
}

.ui-icon-money-off.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-money-off.fa:before {
  content: "money_off";
}

.ui-icon-monochrome-photos.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-monochrome-photos.fa:before {
  content: "monochrome_photos";
}

.ui-icon-mood.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-mood.fa:before {
  content: "mood";
}

.ui-icon-mood-bad.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-mood-bad.fa:before {
  content: "mood_bad";
}

.ui-icon-more.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-more.fa:before {
  content: "more";
}

.ui-icon-more-horiz.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-more-horiz.fa:before {
  content: "more_horiz";
}

.ui-icon-more-vert.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-more-vert.fa:before {
  content: "more_vert";
}

.ui-icon-motorcycle.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-motorcycle.fa:before {
  content: "motorcycle";
}

.ui-icon-mouse.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-mouse.fa:before {
  content: "mouse";
}

.ui-icon-move-to-inbox.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-move-to-inbox.fa:before {
  content: "move_to_inbox";
}

.ui-icon-movie.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-movie.fa:before {
  content: "movie";
}

.ui-icon-movie-creation.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-movie-creation.fa:before {
  content: "movie_creation";
}

.ui-icon-movie-filter.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-movie-filter.fa:before {
  content: "movie_filter";
}

.ui-icon-multiline-chart.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-multiline-chart.fa:before {
  content: "multiline_chart";
}

.ui-icon-music-note.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-music-note.fa:before {
  content: "music_note";
}

.ui-icon-music-video.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-music-video.fa:before {
  content: "music_video";
}

.ui-icon-my-location.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-my-location.fa:before {
  content: "my_location";
}

.ui-icon-nature.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-nature.fa:before {
  content: "nature";
}

.ui-icon-nature-people.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-nature-people.fa:before {
  content: "nature_people";
}

.ui-icon-navigate-before.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-navigate-before.fa:before {
  /* content: "navigate_before"; */
}

.ui-icon-navigate-next.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-navigate-next.fa:before {
  /* content: "navigate_next"; */
}

.ui-icon-navigation.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-navigation.fa:before {
  content: "navigation";
}

.ui-icon-near-me.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-near-me.fa:before {
  content: "near_me";
}

.ui-icon-network-cell.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-network-cell.fa:before {
  content: "network_cell";
}

.ui-icon-network-check.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-network-check.fa:before {
  content: "network_check";
}

.ui-icon-network-locked.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-network-locked.fa:before {
  content: "network_locked";
}

.ui-icon-network-wifi.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-network-wifi.fa:before {
  content: "network_wifi";
}

.ui-icon-new-releases.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-new-releases.fa:before {
  content: "new_releases";
}

.ui-icon-next-week.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-next-week.fa:before {
  content: "next_week";
}

.ui-icon-nfc.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-nfc.fa:before {
  content: "nfc";
}

.ui-icon-no-encryption.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-no-encryption.fa:before {
  content: "no_encryption";
}

.ui-icon-no-sim.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-no-sim.fa:before {
  content: "no_sim";
}

.ui-icon-not-interested.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-not-interested.fa:before {
  content: "not_interested";
}

.ui-icon-note.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-note.fa:before {
  content: "note";
}

.ui-icon-note-add.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-note-add.fa:before {
  content: "note_add";
}

.ui-icon-notifications.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-notifications.fa:before {
  content: "notifications";
}

.ui-icon-notifications-active.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-notifications-active.fa:before {
  content: "notifications_active";
}

.ui-icon-notifications-none.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-notifications-none.fa:before {
  content: "notifications_none";
}

.ui-icon-notifications-off.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-notifications-off.fa:before {
  content: "notifications_off";
}

.ui-icon-notifications-paused.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-notifications-paused.fa:before {
  content: "notifications_paused";
}

.ui-icon-offline-pin.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-offline-pin.fa:before {
  content: "offline_pin";
}

.ui-icon-ondemand-video.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-ondemand-video.fa:before {
  content: "ondemand_video";
}

.ui-icon-opacity.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-opacity.fa:before {
  content: "opacity";
}

.ui-icon-open-in-browser.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-open-in-browser.fa:before {
  content: "open_in_browser";
}

.ui-icon-open-in-new.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-open-in-new.fa:before {
  content: "open_in_new";
}

.ui-icon-open-with.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-open-with.fa:before {
  content: "open_with";
}

.ui-icon-pages.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-pages.fa:before {
  content: "pages";
}

.ui-icon-pageview.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-pageview.fa:before {
  content: "pageview";
}

.ui-icon-palette.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-palette.fa:before {
  content: "palette";
}

.ui-icon-pan-tool.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-pan-tool.fa:before {
  content: "pan_tool";
}

.ui-icon-panorama.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-panorama.fa:before {
  content: "panorama";
}

.ui-icon-panorama-fish-eye.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-panorama-fish-eye.fa:before {
  content: "panorama_fish_eye";
}

.ui-icon-panorama-horizontal.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-panorama-horizontal.fa:before {
  content: "panorama_horizontal";
}

.ui-icon-panorama-vertical.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-panorama-vertical.fa:before {
  content: "panorama_vertical";
}

.ui-icon-panorama-wide-angle.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-panorama-wide-angle.fa:before {
  content: "panorama_wide_angle";
}

.ui-icon-party-mode.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-party-mode.fa:before {
  content: "party_mode";
}

.ui-icon-pause.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-pause.fa:before {
  content: "pause";
}

.ui-icon-pause-circle-filled.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-pause-circle-filled.fa:before {
  content: "pause_circle_filled";
}

.ui-icon-pause-circle-outline.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-pause-circle-outline.fa:before {
  content: "pause_circle_outline";
}

.ui-icon-payment.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-payment.fa:before {
  content: "payment";
}

.ui-icon-people.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-people.fa:before {
  content: "people";
}

.ui-icon-people-outline.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-people-outline.fa:before {
  content: "people_outline";
}

.ui-icon-perm-camera-mic.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-perm-camera-mic.fa:before {
  content: "perm_camera_mic";
}

.ui-icon-perm-contact-calendar.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-perm-contact-calendar.fa:before {
  content: "perm_contact_calendar";
}

.ui-icon-perm-data-setting.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-perm-data-setting.fa:before {
  content: "perm_data_setting";
}

.ui-icon-perm-device-information.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-perm-device-information.fa:before {
  content: "perm_device_information";
}

.ui-icon-perm-identity.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-perm-identity.fa:before {
  content: "perm_identity";
}

.ui-icon-perm-media.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-perm-media.fa:before {
  content: "perm_media";
}

.ui-icon-perm-phone-msg.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-perm-phone-msg.fa:before {
  content: "perm_phone_msg";
}

.ui-icon-perm-scan-wifi.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-perm-scan-wifi.fa:before {
  content: "perm_scan_wifi";
}

.ui-icon-person.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-person.fa:before {
  content: "person";
}

.ui-icon-person-add.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-person-add.fa:before {
  content: "person_add";
}

.ui-icon-person-outline.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-person-outline.fa:before {
  content: "person_outline";
}

.ui-icon-person-pin.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-person-pin.fa:before {
  content: "person_pin";
}

.ui-icon-person-pin-circle.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-person-pin-circle.fa:before {
  content: "person_pin_circle";
}

.ui-icon-personal-video.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-personal-video.fa:before {
  content: "personal_video";
}

.ui-icon-pets.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-pets.fa:before {
  content: "pets";
}

.ui-icon-phone.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-phone.fa:before {
  content: "phone";
}

.ui-icon-phone-android.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-phone-android.fa:before {
  content: "phone_android";
}

.ui-icon-phone-bluetooth-speaker.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-phone-bluetooth-speaker.fa:before {
  content: "phone_bluetooth_speaker";
}

.ui-icon-phone-forwarded.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-phone-forwarded.fa:before {
  content: "phone_forwarded";
}

.ui-icon-phone-in-talk.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-phone-in-talk.fa:before {
  content: "phone_in_talk";
}

.ui-icon-phone-iphone.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-phone-iphone.fa:before {
  content: "phone_iphone";
}

.ui-icon-phone-locked.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-phone-locked.fa:before {
  content: "phone_locked";
}

.ui-icon-phone-missed.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-phone-missed.fa:before {
  content: "phone_missed";
}

.ui-icon-phone-paused.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-phone-paused.fa:before {
  content: "phone_paused";
}

.ui-icon-phonelink.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-phonelink.fa:before {
  content: "phonelink";
}

.ui-icon-phonelink-erase.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-phonelink-erase.fa:before {
  content: "phonelink_erase";
}

.ui-icon-phonelink-lock.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-phonelink-lock.fa:before {
  content: "phonelink_lock";
}

.ui-icon-phonelink-off.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-phonelink-off.fa:before {
  content: "phonelink_off";
}

.ui-icon-phonelink-ring.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-phonelink-ring.fa:before {
  content: "phonelink_ring";
}

.ui-icon-phonelink-setup.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-phonelink-setup.fa:before {
  content: "phonelink_setup";
}

.ui-icon-photo.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-photo.fa:before {
  content: "photo";
}

.ui-icon-photo-album.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-photo-album.fa:before {
  content: "photo_album";
}

.ui-icon-photo-camera.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-photo-camera.fa:before {
  content: "photo_camera";
}

.ui-icon-photo-filter.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-photo-filter.fa:before {
  content: "photo_filter";
}

.ui-icon-photo-library.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-photo-library.fa:before {
  content: "photo_library";
}

.ui-icon-photo-size-select-actual.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-photo-size-select-actual.fa:before {
  content: "photo_size_select_actual";
}

.ui-icon-photo-size-select-large.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-photo-size-select-large.fa:before {
  content: "photo_size_select_large";
}

.ui-icon-photo-size-select-small.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-photo-size-select-small.fa:before {
  content: "photo_size_select_small";
}

.ui-icon-picture-as-pdf.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-picture-as-pdf.fa:before {
  content: "picture_as_pdf";
}

.ui-icon-picture-in-picture.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-picture-in-picture.fa:before {
  content: "picture_in_picture";
}

.ui-icon-picture-in-picture-alt.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-picture-in-picture-alt.fa:before {
  content: "picture_in_picture_alt";
}

.ui-icon-pie-chart.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-pie-chart.fa:before {
  content: "pie_chart";
}

.ui-icon-pie-chart-outlined.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-pie-chart-outlined.fa:before {
  content: "pie_chart_outlined";
}

.ui-icon-pin-drop.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-pin-drop.fa:before {
  content: "pin_drop";
}

.ui-icon-place.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-place.fa:before {
  content: "place";
}

.ui-icon-play-arrow.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-play-arrow.fa:before {
  content: "play_arrow";
}

.ui-icon-play-circle-filled.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-play-circle-filled.fa:before {
  content: "play_circle_filled";
}

.ui-icon-play-circle-outline.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-play-circle-outline.fa:before {
  content: "play_circle_outline";
}

.ui-icon-play-for-work.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-play-for-work.fa:before {
  content: "play_for_work";
}

.ui-icon-playlist-add.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-playlist-add.fa:before {
  content: "playlist_add";
}

.ui-icon-playlist-add-check.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-playlist-add-check.fa:before {
  content: "playlist_add_check";
}

.ui-icon-playlist-play.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-playlist-play.fa:before {
  content: "playlist_play";
}

.ui-icon-plus-one.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-plus-one.fa:before {
  content: "plus_one";
}

.ui-icon-poll.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-poll.fa:before {
  content: "poll";
}

.ui-icon-polymer.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-polymer.fa:before {
  content: "polymer";
}

.ui-icon-pool.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-pool.fa:before {
  content: "pool";
}

.ui-icon-portable-wifi-off.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-portable-wifi-off.fa:before {
  content: "portable_wifi_off";
}

.ui-icon-portrait.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-portrait.fa:before {
  content: "portrait";
}

.ui-icon-power.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-power.fa:before {
  content: "power";
}

.ui-icon-power-input.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-power-input.fa:before {
  content: "power_input";
}

.ui-icon-power-settings-new.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-power-settings-new.fa:before {
  content: "power_settings_new";
}

.ui-icon-pregnant-woman.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-pregnant-woman.fa:before {
  content: "pregnant_woman";
}

.ui-icon-present-to-all.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-present-to-all.fa:before {
  content: "present_to_all";
}

.ui-icon-print.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-print.fa:before {
  content: "print";
}

.ui-icon-priority-high.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-priority-high.fa:before {
  content: "priority_high";
}

.ui-icon-public.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-public.fa:before {
  content: "public";
}

.ui-icon-publish.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-publish.fa:before {
  content: "publish";
}

.ui-icon-query-builder.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-query-builder.fa:before {
  content: "query_builder";
}

.ui-icon-question-answer.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-question-answer.fa:before {
  content: "question_answer";
}

.ui-icon-queue.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-queue.fa:before {
  content: "queue";
}

.ui-icon-queue-music.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-queue-music.fa:before {
  content: "queue_music";
}

.ui-icon-queue-play-next.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-queue-play-next.fa:before {
  content: "queue_play_next";
}

.ui-icon-radio.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-radio.fa:before {
  content: "radio";
}

.ui-icon-radio-button-checked.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-radio-button-checked.fa:before {
  content: "radio_button_checked";
}

.ui-icon-radio-button-unchecked.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-radio-button-unchecked.fa:before {
  content: "radio_button_unchecked";
}

.ui-icon-rate-review.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-rate-review.fa:before {
  content: "rate_review";
}

.ui-icon-receipt.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-receipt.fa:before {
  content: "receipt";
}

.ui-icon-recent-actors.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-recent-actors.fa:before {
  content: "recent_actors";
}

.ui-icon-record-voice-over.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-record-voice-over.fa:before {
  content: "record_voice_over";
}

.ui-icon-redeem.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-redeem.fa:before {
  content: "redeem";
}

.ui-icon-redo.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-redo.fa:before {
  content: "redo";
}

.ui-icon-refresh.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-refresh.fa:before {
  content: "refresh";
}

.ui-icon-remove.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-remove.fa:before {
  content: "remove";
}

.ui-icon-remove-circle.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-remove-circle.fa:before {
  content: "remove_circle";
}

.ui-icon-remove-circle-outline.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-remove-circle-outline.fa:before {
  content: "remove_circle_outline";
}

.ui-icon-remove-from-queue.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-remove-from-queue.fa:before {
  content: "remove_from_queue";
}

.ui-icon-remove-red-eye.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-remove-red-eye.fa:before {
  content: "remove_red_eye";
}

.ui-icon-remove-shopping-cart.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-remove-shopping-cart.fa:before {
  content: "remove_shopping_cart";
}

.ui-icon-reorder.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-reorder.fa:before {
  content: "reorder";
}

.ui-icon-repeat.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-repeat.fa:before {
  content: "repeat";
}

.ui-icon-repeat-one.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-repeat-one.fa:before {
  content: "repeat_one";
}

.ui-icon-replay.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-replay.fa:before {
  content: "replay";
}

.ui-icon-replay-10.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-replay-10.fa:before {
  content: "replay_10";
}

.ui-icon-replay-30.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-replay-30.fa:before {
  content: "replay_30";
}

.ui-icon-replay-5.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-replay-5.fa:before {
  content: "replay_5";
}

.ui-icon-reply.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-reply.fa:before {
  content: "reply";
}

.ui-icon-reply-all.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-reply-all.fa:before {
  content: "reply_all";
}

.ui-icon-report.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-report.fa:before {
  content: "report";
}

.ui-icon-report-problem.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-report-problem.fa:before {
  content: "report_problem";
}

.ui-icon-restaurant.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-restaurant.fa:before {
  content: "restaurant";
}

.ui-icon-restaurant-menu.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-restaurant-menu.fa:before {
  content: "restaurant_menu";
}

.ui-icon-restore.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-restore.fa:before {
  content: "restore";
}

.ui-icon-restore-page.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-restore-page.fa:before {
  content: "restore_page";
}

.ui-icon-ring-volume.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-ring-volume.fa:before {
  content: "ring_volume";
}

.ui-icon-room.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-room.fa:before {
  content: "room";
}

.ui-icon-room-service.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-room-service.fa:before {
  content: "room_service";
}

.ui-icon-rotate-90-degrees-ccw.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-rotate-90-degrees-ccw.fa:before {
  content: "rotate_90_degrees_ccw";
}

.ui-icon-rotate-left.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-rotate-left.fa:before {
  content: "rotate_left";
}

.ui-icon-rotate-right.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-rotate-right.fa:before {
  content: "rotate_right";
}

.ui-icon-rounded-corner.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-rounded-corner.fa:before {
  content: "rounded_corner";
}

.ui-icon-router.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-router.fa:before {
  content: "router";
}

.ui-icon-rowing.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-rowing.fa:before {
  content: "rowing";
}

.ui-icon-rss-feed.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-rss-feed.fa:before {
  content: "rss_feed";
}

.ui-icon-rv-hookup.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-rv-hookup.fa:before {
  content: "rv_hookup";
}

.ui-icon-satellite.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-satellite.fa:before {
  content: "satellite";
}

.ui-icon-save.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-save.fa:before {
  content: "save";
}

.ui-icon-scanner.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-scanner.fa:before {
  content: "scanner";
}

.ui-icon-schedule.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-schedule.fa:before {
  content: "schedule";
}

.ui-icon-school.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-school.fa:before {
  content: "school";
}

.ui-icon-screen-lock-landscape.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-screen-lock-landscape.fa:before {
  content: "screen_lock_landscape";
}

.ui-icon-screen-lock-portrait.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-screen-lock-portrait.fa:before {
  content: "screen_lock_portrait";
}

.ui-icon-screen-lock-rotation.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-screen-lock-rotation.fa:before {
  content: "screen_lock_rotation";
}

.ui-icon-screen-rotation.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-screen-rotation.fa:before {
  content: "screen_rotation";
}

.ui-icon-screen-share.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-screen-share.fa:before {
  content: "screen_share";
}

.ui-icon-sd-card.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-sd-card.fa:before {
  content: "sd_card";
}

.ui-icon-sd-storage.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-sd-storage.fa:before {
  content: "sd_storage";
}

.ui-icon-search.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-search.fa:before {
  content: "search";
}

.ui-icon-security.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-security.fa:before {
  content: "security";
}

.ui-icon-select-all.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-select-all.fa:before {
  content: "select_all";
}

.ui-icon-send.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-send.fa:before {
  content: "send";
}

.ui-icon-sentiment-dissatisfied.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-sentiment-dissatisfied.fa:before {
  content: "sentiment_dissatisfied";
}

.ui-icon-sentiment-neutral.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-sentiment-neutral.fa:before {
  content: "sentiment_neutral";
}

.ui-icon-sentiment-satisfied.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-sentiment-satisfied.fa:before {
  content: "sentiment_satisfied";
}

.ui-icon-sentiment-very-dissatisfied.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-sentiment-very-dissatisfied.fa:before {
  content: "sentiment_very_dissatisfied";
}

.ui-icon-sentiment-very-satisfied.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-sentiment-very-satisfied.fa:before {
  content: "sentiment_very_satisfied";
}

.ui-icon-settings.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-settings.fa:before {
  content: "settings";
}

.ui-icon-settings-applications.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-settings-applications.fa:before {
  content: "settings_applications";
}

.ui-icon-settings-backup-restore.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-settings-backup-restore.fa:before {
  content: "settings_backup_restore";
}

.ui-icon-settings-bluetooth.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-settings-bluetooth.fa:before {
  content: "settings_bluetooth";
}

.ui-icon-settings-brightness.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-settings-brightness.fa:before {
  content: "settings_brightness";
}

.ui-icon-settings-cell.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-settings-cell.fa:before {
  content: "settings_cell";
}

.ui-icon-settings-ethernet.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-settings-ethernet.fa:before {
  content: "settings_ethernet";
}

.ui-icon-settings-input-antenna.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-settings-input-antenna.fa:before {
  content: "settings_input_antenna";
}

.ui-icon-settings-input-component.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-settings-input-component.fa:before {
  content: "settings_input_component";
}

.ui-icon-settings-input-composite.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-settings-input-composite.fa:before {
  content: "settings_input_composite";
}

.ui-icon-settings-input-hdmi.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-settings-input-hdmi.fa:before {
  content: "settings_input_hdmi";
}

.ui-icon-settings-input-svideo.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-settings-input-svideo.fa:before {
  content: "settings_input_svideo";
}

.ui-icon-settings-overscan.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-settings-overscan.fa:before {
  content: "settings_overscan";
}

.ui-icon-settings-phone.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-settings-phone.fa:before {
  content: "settings_phone";
}

.ui-icon-settings-power.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-settings-power.fa:before {
  content: "settings_power";
}

.ui-icon-settings-remote.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-settings-remote.fa:before {
  content: "settings_remote";
}

.ui-icon-settings-system-daydream.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-settings-system-daydream.fa:before {
  content: "settings_system_daydream";
}

.ui-icon-settings-voice.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-settings-voice.fa:before {
  content: "settings_voice";
}

.ui-icon-share.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-share.fa:before {
  content: "share";
}

.ui-icon-shop.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-shop.fa:before {
  content: "shop";
}

.ui-icon-shop-two.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-shop-two.fa:before {
  content: "shop_two";
}

.ui-icon-shopping-basket.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-shopping-basket.fa:before {
  content: "shopping_basket";
}

.ui-icon-shopping-cart.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-shopping-cart.fa:before {
  content: "shopping_cart";
}

.ui-icon-short-text.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-short-text.fa:before {
  content: "short_text";
}

.ui-icon-show-chart.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-show-chart.fa:before {
  content: "show_chart";
}

.ui-icon-shuffle.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-shuffle.fa:before {
  content: "shuffle";
}

.ui-icon-signal-cellular-4-bar.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-signal-cellular-4-bar.fa:before {
  content: "signal_cellular_4_bar";
}

.ui-icon-signal-cellular-connected-no-internet-4-bar.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-signal-cellular-connected-no-internet-4-bar.fa:before {
  content: "signal_cellular_connected_no_internet_4_bar";
}

.ui-icon-signal-cellular-no-sim.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-signal-cellular-no-sim.fa:before {
  content: "signal_cellular_no_sim";
}

.ui-icon-signal-cellular-null.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-signal-cellular-null.fa:before {
  content: "signal_cellular_null";
}

.ui-icon-signal-cellular-off.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-signal-cellular-off.fa:before {
  content: "signal_cellular_off";
}

.ui-icon-signal-wifi-4-bar.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-signal-wifi-4-bar.fa:before {
  content: "signal_wifi_4_bar";
}

.ui-icon-signal-wifi-4-bar-lock.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-signal-wifi-4-bar-lock.fa:before {
  content: "signal_wifi_4_bar_lock";
}

.ui-icon-signal-wifi-off.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-signal-wifi-off.fa:before {
  content: "signal_wifi_off";
}

.ui-icon-sim-card.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-sim-card.fa:before {
  content: "sim_card";
}

.ui-icon-sim-card-alert.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-sim-card-alert.fa:before {
  content: "sim_card_alert";
}

.ui-icon-skip-next.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-skip-next.fa:before {
  content: "skip_next";
}

.ui-icon-skip-previous.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-skip-previous.fa:before {
  content: "skip_previous";
}

.ui-icon-slideshow.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-slideshow.fa:before {
  content: "slideshow";
}

.ui-icon-slow-motion-video.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-slow-motion-video.fa:before {
  content: "slow_motion_video";
}

.ui-icon-smartphone.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-smartphone.fa:before {
  content: "smartphone";
}

.ui-icon-smoke-free.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-smoke-free.fa:before {
  content: "smoke_free";
}

.ui-icon-smoking-rooms.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-smoking-rooms.fa:before {
  content: "smoking_rooms";
}

.ui-icon-sms.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-sms.fa:before {
  content: "sms";
}

.ui-icon-sms-failed.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-sms-failed.fa:before {
  content: "sms_failed";
}

.ui-icon-snooze.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-snooze.fa:before {
  content: "snooze";
}

.ui-icon-sort.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-sort.fa:before {
  content: "sort";
}

.ui-icon-sort-by-alpha.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-sort-by-alpha.fa:before {
  content: "sort_by_alpha";
}

.ui-icon-spa.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-spa.fa:before {
  content: "spa";
}

.ui-icon-space-bar.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-space-bar.fa:before {
  content: "space_bar";
}

.ui-icon-speaker.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-speaker.fa:before {
  content: "speaker";
}

.ui-icon-speaker-group.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-speaker-group.fa:before {
  content: "speaker_group";
}

.ui-icon-speaker-notes.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-speaker-notes.fa:before {
  content: "speaker_notes";
}

.ui-icon-speaker-notes-off.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-speaker-notes-off.fa:before {
  content: "speaker_notes_off";
}

.ui-icon-speaker-phone.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-speaker-phone.fa:before {
  content: "speaker_phone";
}

.ui-icon-spellcheck.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-spellcheck.fa:before {
  content: "spellcheck";
}

.ui-icon-star.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-star.fa:before {
  content: "star";
}

.ui-icon-star-border.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-star-border.fa:before {
  content: "star_border";
}

.ui-icon-star-half.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-star-half.fa:before {
  content: "star_half";
}

.ui-icon-stars.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-stars.fa:before {
  content: "stars";
}

.ui-icon-stay-current-landscape.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-stay-current-landscape.fa:before {
  content: "stay_current_landscape";
}

.ui-icon-stay-current-portrait.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-stay-current-portrait.fa:before {
  content: "stay_current_portrait";
}

.ui-icon-stay-primary-landscape.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-stay-primary-landscape.fa:before {
  content: "stay_primary_landscape";
}

.ui-icon-stay-primary-portrait.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-stay-primary-portrait.fa:before {
  content: "stay_primary_portrait";
}

.ui-icon-stop.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-stop.fa:before {
  content: "stop";
}

.ui-icon-stop-screen-share.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-stop-screen-share.fa:before {
  content: "stop_screen_share";
}

.ui-icon-storage.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-storage.fa:before {
  content: "storage";
}

.ui-icon-store.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-store.fa:before {
  content: "store";
}

.ui-icon-store-mall-directory.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-store-mall-directory.fa:before {
  content: "store_mall_directory";
}

.ui-icon-straighten.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-straighten.fa:before {
  content: "straighten";
}

.ui-icon-streetview.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-streetview.fa:before {
  content: "streetview";
}

.ui-icon-strikethrough-s.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-strikethrough-s.fa:before {
  content: "strikethrough_s";
}

.ui-icon-style.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-style.fa:before {
  content: "style";
}

.ui-icon-subdirectory-arrow-left.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-subdirectory-arrow-left.fa:before {
  content: "subdirectory_arrow_left";
}

.ui-icon-subdirectory-arrow-right.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-subdirectory-arrow-right.fa:before {
  content: "subdirectory_arrow_right";
}

.ui-icon-subject.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-subject.fa:before {
  content: "subject";
}

.ui-icon-subscriptions.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-subscriptions.fa:before {
  content: "subscriptions";
}

.ui-icon-subtitles.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-subtitles.fa:before {
  content: "subtitles";
}

.ui-icon-subway.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-subway.fa:before {
  content: "subway";
}

.ui-icon-supervisor-account.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-supervisor-account.fa:before {
  content: "supervisor_account";
}

.ui-icon-surround-sound.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-surround-sound.fa:before {
  content: "surround_sound";
}

.ui-icon-swap-calls.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-swap-calls.fa:before {
  content: "swap_calls";
}

.ui-icon-swap-horiz.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-swap-horiz.fa:before {
  content: "swap_horiz";
}

.ui-icon-swap-vert.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-swap-vert.fa:before {
  content: "swap_vert";
}

.ui-icon-swap-vertical-circle.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-swap-vertical-circle.fa:before {
  content: "swap_vertical_circle";
}

.ui-icon-switch-camera.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-switch-camera.fa:before {
  content: "switch_camera";
}

.ui-icon-switch-video.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-switch-video.fa:before {
  content: "switch_video";
}

.ui-icon-sync.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-sync.fa:before {
  content: "sync";
}

.ui-icon-sync-disabled.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-sync-disabled.fa:before {
  content: "sync_disabled";
}

.ui-icon-sync-problem.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-sync-problem.fa:before {
  content: "sync_problem";
}

.ui-icon-system-update.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-system-update.fa:before {
  content: "system_update";
}

.ui-icon-system-update-alt.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-system-update-alt.fa:before {
  content: "system_update_alt";
}

.ui-icon-tab.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-tab.fa:before {
  content: "tab";
}

.ui-icon-tab-unselected.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-tab-unselected.fa:before {
  content: "tab_unselected";
}

.ui-icon-tablet.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-tablet.fa:before {
  content: "tablet";
}

.ui-icon-tablet-android.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-tablet-android.fa:before {
  content: "tablet_android";
}

.ui-icon-tablet-mac.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-tablet-mac.fa:before {
  content: "tablet_mac";
}

.ui-icon-tag-faces.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-tag-faces.fa:before {
  content: "tag_faces";
}

.ui-icon-tap-and-play.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-tap-and-play.fa:before {
  content: "tap_and_play";
}

.ui-icon-terrain.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-terrain.fa:before {
  content: "terrain";
}

.ui-icon-text-fields.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-text-fields.fa:before {
  content: "text_fields";
}

.ui-icon-text-format.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-text-format.fa:before {
  content: "text_format";
}

.ui-icon-textsms.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-textsms.fa:before {
  content: "textsms";
}

.ui-icon-texture.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-texture.fa:before {
  content: "texture";
}

.ui-icon-theaters.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-theaters.fa:before {
  content: "theaters";
}

.ui-icon-thumb-down.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-thumb-down.fa:before {
  content: "thumb_down";
}

.ui-icon-thumb-up.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-thumb-up.fa:before {
  content: "thumb_up";
}

.ui-icon-thumbs-up-down.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-thumbs-up-down.fa:before {
  content: "thumbs_up_down";
}

.ui-icon-time-to-leave.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-time-to-leave.fa:before {
  content: "time_to_leave";
}

.ui-icon-timelapse.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-timelapse.fa:before {
  content: "timelapse";
}

.ui-icon-timeline.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-timeline.fa:before {
  content: "timeline";
}

.ui-icon-timer.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-timer.fa:before {
  content: "timer";
}

.ui-icon-timer-10.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-timer-10.fa:before {
  content: "timer_10";
}

.ui-icon-timer-3.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-timer-3.fa:before {
  content: "timer_3";
}

.ui-icon-timer-off.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-timer-off.fa:before {
  content: "timer_off";
}

.ui-icon-title.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-title.fa:before {
  content: "title";
}

.ui-icon-toc.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-toc.fa:before {
  content: "toc";
}

.ui-icon-today.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-today.fa:before {
  content: "today";
}

.ui-icon-toll.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-toll.fa:before {
  content: "toll";
}

.ui-icon-tonality.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-tonality.fa:before {
  content: "tonality";
}

.ui-icon-touch-app.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-touch-app.fa:before {
  content: "touch_app";
}

.ui-icon-toys.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-toys.fa:before {
  content: "toys";
}

.ui-icon-track-changes.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-track-changes.fa:before {
  content: "track_changes";
}

.ui-icon-traffic.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-traffic.fa:before {
  content: "traffic";
}

.ui-icon-train.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-train.fa:before {
  content: "train";
}

.ui-icon-tram.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-tram.fa:before {
  content: "tram";
}

.ui-icon-transfer-within-a-station.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-transfer-within-a-station.fa:before {
  content: "transfer_within_a_station";
}

.ui-icon-transform.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-transform.fa:before {
  content: "transform";
}

.ui-icon-translate.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-translate.fa:before {
  content: "translate";
}

.ui-icon-trending-down.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-trending-down.fa:before {
  content: "trending_down";
}

.ui-icon-trending-flat.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-trending-flat.fa:before {
  content: "trending_flat";
}

.ui-icon-trending-up.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-trending-up.fa:before {
  content: "trending_up";
}

.ui-icon-tune.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-tune.fa:before {
  content: "tune";
}

.ui-icon-turned-in.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-turned-in.fa:before {
  content: "turned_in";
}

.ui-icon-turned-in-not.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-turned-in-not.fa:before {
  content: "turned_in_not";
}

.ui-icon-tv.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-tv.fa:before {
  content: "tv";
}

.ui-icon-unarchive.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-unarchive.fa:before {
  content: "unarchive";
}

.ui-icon-undo.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-undo.fa:before {
  content: "undo";
}

.ui-icon-unfold-less.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-unfold-less.fa:before {
  content: "unfold_less";
}

.ui-icon-unfold-more.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-unfold-more.fa:before {
  content: "unfold_more";
}

.ui-icon-update.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-update.fa:before {
  content: "update";
}

.ui-icon-usb.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-usb.fa:before {
  content: "usb";
}

.ui-icon-verified-user.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-verified-user.fa:before {
  content: "verified_user";
}

.ui-icon-vertical-align-bottom.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-vertical-align-bottom.fa:before {
  content: "vertical_align_bottom";
}

.ui-icon-vertical-align-center.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-vertical-align-center.fa:before {
  content: "vertical_align_center";
}

.ui-icon-vertical-align-top.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-vertical-align-top.fa:before {
  content: "vertical_align_top";
}

.ui-icon-vibration.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-vibration.fa:before {
  content: "vibration";
}

.ui-icon-video-call.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-video-call.fa:before {
  content: "video_call";
}

.ui-icon-video-label.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-video-label.fa:before {
  content: "video_label";
}

.ui-icon-video-library.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-video-library.fa:before {
  content: "video_library";
}

.ui-icon-videocam.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-videocam.fa:before {
  content: "videocam";
}

.ui-icon-videocam-off.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-videocam-off.fa:before {
  content: "videocam_off";
}

.ui-icon-videogame-asset.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-videogame-asset.fa:before {
  content: "videogame_asset";
}

.ui-icon-view-agenda.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-view-agenda.fa:before {
  content: "view_agenda";
}

.ui-icon-view-array.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-view-array.fa:before {
  content: "view_array";
}

.ui-icon-view-carousel.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-view-carousel.fa:before {
  content: "view_carousel";
}

.ui-icon-view-column.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-view-column.fa:before {
  content: "view_column";
}

.ui-icon-view-comfy.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-view-comfy.fa:before {
  content: "view_comfy";
}

.ui-icon-view-compact.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-view-compact.fa:before {
  content: "view_compact";
}

.ui-icon-view-day.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-view-day.fa:before {
  content: "view_day";
}

.ui-icon-view-headline.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-view-headline.fa:before {
  content: "view_headline";
}

.ui-icon-view-list.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-view-list.fa:before {
  content: "view_list";
}

.ui-icon-view-module.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-view-module.fa:before {
  content: "view_module";
}

.ui-icon-view-quilt.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-view-quilt.fa:before {
  content: "view_quilt";
}

.ui-icon-view-stream.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-view-stream.fa:before {
  content: "view_stream";
}

.ui-icon-view-week.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-view-week.fa:before {
  content: "view_week";
}

.ui-icon-vignette.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-vignette.fa:before {
  content: "vignette";
}

.ui-icon-visibility.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-visibility.fa:before {
  content: "visibility";
}

.ui-icon-visibility-off.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-visibility-off.fa:before {
  content: "visibility_off";
}

.ui-icon-voice-chat.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-voice-chat.fa:before {
  content: "voice_chat";
}

.ui-icon-voicemail.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-voicemail.fa:before {
  content: "voicemail";
}

.ui-icon-volume-down.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-volume-down.fa:before {
  content: "volume_down";
}

.ui-icon-volume-mute.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-volume-mute.fa:before {
  content: "volume_mute";
}

.ui-icon-volume-off.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-volume-off.fa:before {
  content: "volume_off";
}

.ui-icon-volume-up.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-volume-up.fa:before {
  content: "volume_up";
}

.ui-icon-vpn-key.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-vpn-key.fa:before {
  content: "vpn_key";
}

.ui-icon-vpn-lock.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-vpn-lock.fa:before {
  content: "vpn_lock";
}

.ui-icon-wallpaper.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-wallpaper.fa:before {
  content: "wallpaper";
}

.ui-icon-warning.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-warning.fa:before {
  content: "warning";
}

.ui-icon-watch.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-watch.fa:before {
  content: "watch";
}

.ui-icon-watch-later.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-watch-later.fa:before {
  content: "watch_later";
}

.ui-icon-wb-auto.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-wb-auto.fa:before {
  content: "wb_auto";
}

.ui-icon-wb-cloudy.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-wb-cloudy.fa:before {
  content: "wb_cloudy";
}

.ui-icon-wb-incandescent.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-wb-incandescent.fa:before {
  content: "wb_incandescent";
}

.ui-icon-wb-iridescent.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-wb-iridescent.fa:before {
  content: "wb_iridescent";
}

.ui-icon-wb-sunny.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-wb-sunny.fa:before {
  content: "wb_sunny";
}

.ui-icon-wc.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-wc.fa:before {
  content: "wc";
}

.ui-icon-web.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-web.fa:before {
  content: "web";
}

.ui-icon-web-asset.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-web-asset.fa:before {
  content: "web_asset";
}

.ui-icon-weekend.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-weekend.fa:before {
  content: "weekend";
}

.ui-icon-whatshot.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-whatshot.fa:before {
  content: "whatshot";
}

.ui-icon-widgets.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-widgets.fa:before {
  content: "widgets";
}

.ui-icon-wifi.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-wifi.fa:before {
  content: "wifi";
}

.ui-icon-wifi-lock.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-wifi-lock.fa:before {
  content: "wifi_lock";
}

.ui-icon-wifi-tethering.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-wifi-tethering.fa:before {
  content: "wifi_tethering";
}

.ui-icon-work.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-work.fa:before {
  content: "work";
}

.ui-icon-wrap-text.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-wrap-text.fa:before {
  content: "wrap_text";
}

.ui-icon-youtube-searched-for.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-youtube-searched-for.fa:before {
  content: "youtube_searched_for";
}

.ui-icon-zoom-in.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-zoom-in.fa:before {
  content: "zoom_in";
}

.ui-icon-zoom-out.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-zoom-out.fa:before {
  content: "zoom_out";
}

.ui-icon-zoom-out-map.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ui-icon-zoom-out-map.fa:before {
  content: "zoom_out_map";
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

body .ui-widget,
body .ui-widget .ui-widget {
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  text-decoration: none;
}

body .ui-widget-content {
  background-color: #ffffff;
  font-size: 1em;
  /*border: 1px solid #bdbdbd;*/
}

body .ui-widget-content .fa {
  color: #757575;
}

body .ui-widget-header {
  color: #000;
  font-size: 1em;
  border: 1px solid #cacaca;
}

body .ui-widget-header .fa {
  color: #000;
}

body .ui-state-active,
body .ui-state-highlight {
  background-color: #3bafda;
  color: #ffffff;
}

body .ui-state-active .fa,
body .ui-state-highlight .fa {
  color: #ffffff;
}

body .ui-widget:disabled,
body .ui-state-disabled {
  opacity: .35;
  filter: Alpha(Opacity=35);
  background-image: none;
  cursor: auto !important;
}

body .ui-corner-all {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

body .ui-corner-top {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
}

body .ui-corner-bottom {
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

body .ui-corner-left {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

body .ui-corner-right {
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

body .ui-widget-overlay {
  background-color: #58575c;
  opacity: 0.98;
}

body {
  /* InputGroup */
  /* Validation */
}

body .ui-inputtext {
  background: transparent no-repeat;
  /* background-image: linear-gradient(to bottom, #3bafda, #3bafda), linear-gradient(to bottom, #bdbdbd, #bdbdbd); */
  background-size: 0 2px, 100% 1px;
  background-position: 50% 100%, 50% 100%;
  transition: background-size 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  border-width: 0;
  padding: 2px;
  font-size: 1em;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

body .ui-inputtext:focus,
body .ui-inputtext.ui-state-focus {
  border-width: 0;
  background-size: 100% 2px, 100% 1px;
  outline: none;
  padding-bottom: 2px;
}

body .ui-inputtext:disabled {
  border-bottom: 1px dotted;
}

body .ui-inputtext.ui-widget-content {
  border-width: 1px;
  background: transparent;
  background-image: none;
}

body .ui-inputtext.ui-state-error {
  border-color: #e62a10;
}

body .ui-inputtext:-webkit-autofill {
  border-color: #bdbdbd;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
}

body .ui-inputtext:-webkit-autofill.ui-state-focus {
  padding-bottom: 0px;
}

body .ui-widget-header .ui-inputtext {
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #98a2db, #98a2db);
}

body .md-inputfield {
  display: block;
  position: relative;
}

body .md-inputfield>label {
  color: #999;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 1px;
  transition: 0.3s ease all;
  -moz-transition: 0.3s ease all;
  -webkit-transition: 0.3s ease all;
}

body .md-inputfield input:focus~label,
body .md-inputfield input.ui-state-filled~label,
body .md-inputfield .ui-inputwrapper-focus~label,
body .md-inputfield .ui-inputwrapper-filled~label {
  top: -20px;
  font-size: 12px;
  color: #3bafda;
}

body .md-inputfield .input:-webkit-autofill~label {
  top: -20px;
  font-size: 12px;
  color: #3bafda;
}

body .md-inputfield input.ng-dirty.ng-invalid~label {
  color: #e62a10;
}

body .md-inputfield .ui-message {
  position: absolute;
}

body .md-inputfield .ui-message.ui-messages-error {
  background-color: transparent;
  border: 0 none;
  margin: 0px;
  color: #e62a10;
  font-size: .75em;
}

body .md-inputfield.md-inputfield-fill input {
  background-color: #f7f7f7;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 4px;
}

body .md-inputfield.md-inputfield-fill label {
  top: 2px;
}

body .md-inputfield.md-inputfield-fill input:focus~label,
body .md-inputfield.md-inputfield-fill input.ui-state-filled~label,
body .md-inputfield.md-inputfield-fill .ui-inputwrapper-focus~label,
body .md-inputfield.md-inputfield-fill .ui-inputwrapper-filled~label {
  top: -20px;
  font-size: 12px;
  color: #3bafda;
}

body .md-inputfield.md-inputfield-box {
  background-color: #f7f7f7;
  height: 44px;
}

body .md-inputfield.md-inputfield-box>*:not(label) {
  bottom: 0px;
  position: absolute;
  background-color: #f7f7f7;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 24px;
}

body .md-inputfield.md-inputfield-box>label {
  top: 23px;
}

body .md-inputfield.md-inputfield-box input:focus~label,
body .md-inputfield.md-inputfield-box input.ui-state-filled~label,
body .md-inputfield.md-inputfield-box .ui-inputwrapper-focus~label,
body .md-inputfield.md-inputfield-box .ui-inputwrapper-filled~label {
  top: 1px;
}

body .md-inputfield.md-inputfield-box .input:-webkit-autofill~label {
  top: 1px;
}

body .ui-material-message.ui-message.ui-messages-error {
  background-color: transparent;
  border: 0 none;
  margin: 0px;
  color: #e62a10;
  font-size: .75em;
}

body .ui-material-message.ui-message.ui-messages-error label {
  top: 4px;
}

body .ui-listbox {
  min-width: 12.5em;
  background-color: #ffffff;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

body .ui-listbox.ui-inputtext {
  padding: 0;
}

body .ui-listbox .ui-listbox-list {
  padding: 0;
}

body .ui-listbox .ui-listbox-item {
  overflow: hidden;
  font-size: 1em;
  padding: 0.625em 0.875em;
  margin: 0;
  color: #212121;
  position: relative;
  overflow: hidden;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

body .ui-listbox .ui-listbox-item.ui-state-highlight {
  background-color: #f47264;
  color: #ffffff;
}

body .ui-listbox:not(.ui-state-disabled) .ui-listbox-item:not(.ui-state-highlight):hover {
  background-color: #e8e8e8;
  color: #000000;
}

body .ui-listbox .ui-listbox-header {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  padding: 0.625em 1em;
}

body .ui-listbox .ui-listbox-header .ui-chkbox .ui-chkbox-box {
  border-color: #ffffff;
}

body .ui-listbox .ui-listbox-header .ui-listbox-filter-container .ui-inputtext {
  padding: 2px;
  padding-left: 1.5em;
}

body .ui-listbox .ui-listbox-header .ui-listbox-filter-container .fa {
  top: 0;
  left: 0;
}

body .ui-button,
body .ui-datepicker-buttonpane>button {
  overflow: hidden;
  background-color: #3bafda;
  color: #ffffff;
  font-size: 1em;
  height: 2.25em;
  padding: 0 1em;
  border: 0 none;
  -moz-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  vertical-align: middle;
}

body .ui-button:enabled:not(:focus):hover,
body .ui-datepicker-buttonpane>button:enabled:not(:focus):hover {
  background-color: #3bafda;
}

body .ui-button:focus,
body .ui-datepicker-buttonpane>button:focus {
  outline: 0 none;
  background-color: #606fc7;
}

body .ui-button .ui-button-text,
body .ui-datepicker-buttonpane>button .ui-button-text {
  padding: 0;
  line-height: 2.25em;
}

body .ui-button .fa,
body .ui-datepicker-buttonpane>button .fa {
  color: #ffffff;
}

body .ui-button.ui-button-icon-only,
body .ui-datepicker-buttonpane>button.ui-button-icon-only {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
}

body .ui-button.ui-button-icon-only .fa,
body .ui-datepicker-buttonpane>button.ui-button-icon-only .fa {
  margin-left: -.5em;
}

body .ui-button.ui-button-text-icon-left,
body .ui-datepicker-buttonpane>button.ui-button-text-icon-left {
  padding-left: 2.5em;
}

body .ui-button.ui-button-text-icon-right,
body .ui-datepicker-buttonpane>button.ui-button-text-icon-right {
  padding-right: 2.5em;
}

body .ui-button.secondary,
body .ui-button.ui-secondary-button,
body .ui-datepicker-buttonpane>button.secondary,
body .ui-datepicker-buttonpane>button.ui-secondary-button {
  background-color: #f47264;
  color: #ffffff;
}

body .ui-button.secondary:enabled:not(:focus):hover,
body .ui-button.ui-secondary-button:enabled:not(:focus):hover,
body .ui-datepicker-buttonpane>button.secondary:enabled:not(:focus):hover,
body .ui-datepicker-buttonpane>button.ui-secondary-button:enabled:not(:focus):hover {
  background-color: #ad1457;
}

body .ui-button.secondary:focus,
body .ui-button.ui-secondary-button:focus,
body .ui-datepicker-buttonpane>button.secondary:focus,
body .ui-datepicker-buttonpane>button.ui-secondary-button:focus {
  outline: 0 none;
  background-color: #ee4c83;
}

body .ui-button.blue-grey-btn,
body .ui-datepicker-buttonpane>button.blue-grey-btn {
  background-color: #607D8B;
}

body .ui-button.blue-grey-btn:enabled:not(:focus):hover,
body .ui-datepicker-buttonpane>button.blue-grey-btn:enabled:not(:focus):hover {
  background-color: #37474F;
}

body .ui-button.blue-grey-btn:focus,
body .ui-datepicker-buttonpane>button.blue-grey-btn:focus {
  outline: 0 none;
  background-color: #7b96a3;
}

body .ui-button.cyan-btn,
body .ui-datepicker-buttonpane>button.cyan-btn {
  background-color: #00BCD4;
}

body .ui-button.cyan-btn:enabled:not(:focus):hover,
body .ui-datepicker-buttonpane>button.cyan-btn:enabled:not(:focus):hover {
  background-color: #00838F;
}

body .ui-button.cyan-btn:focus,
body .ui-datepicker-buttonpane>button.cyan-btn:focus {
  outline: 0 none;
  background-color: #08e3ff;
}

body .ui-button.teal-btn,
body .ui-datepicker-buttonpane>button.teal-btn {
  background-color: #009688;
}

body .ui-button.teal-btn:enabled:not(:focus):hover,
body .ui-datepicker-buttonpane>button.teal-btn:enabled:not(:focus):hover {
  background-color: #00695C;
}

body .ui-button.teal-btn:focus,
body .ui-datepicker-buttonpane>button.teal-btn:focus {
  outline: 0 none;
  background-color: #00c9b6;
}

body .ui-button.red-btn,
body .ui-button.ui-danger-button,
body .ui-datepicker-buttonpane>button.red-btn,
body .ui-datepicker-buttonpane>button.ui-danger-button {
  background-color: #F44336;
}

body .ui-button.red-btn:enabled:not(:focus):hover,
body .ui-button.ui-danger-button:enabled:not(:focus):hover,
body .ui-datepicker-buttonpane>button.red-btn:enabled:not(:focus):hover,
body .ui-datepicker-buttonpane>button.ui-danger-button:enabled:not(:focus):hover {
  background-color: #C62828;
}

body .ui-button.red-btn:focus,
body .ui-button.ui-danger-button:focus,
body .ui-datepicker-buttonpane>button.red-btn:focus,
body .ui-datepicker-buttonpane>button.ui-danger-button:focus {
  outline: 0 none;
  background-color: #f77066;
}

body .ui-button.green-btn,
body .ui-button.ui-success-button,
body .ui-datepicker-buttonpane>button.green-btn,
body .ui-datepicker-buttonpane>button.ui-success-button {
  background-color: #4CAF50;
}

body .ui-button.green-btn:enabled:not(:focus):hover,
body .ui-button.ui-success-button:enabled:not(:focus):hover,
body .ui-datepicker-buttonpane>button.green-btn:enabled:not(:focus):hover,
body .ui-datepicker-buttonpane>button.ui-success-button:enabled:not(:focus):hover {
  background-color: #2E7D32;
}

body .ui-button.green-btn:focus,
body .ui-button.ui-success-button:focus,
body .ui-datepicker-buttonpane>button.green-btn:focus,
body .ui-datepicker-buttonpane>button.ui-success-button:focus {
  outline: 0 none;
  background-color: #6ec071;
}

body .ui-button.deep-orange-btn,
body .ui-datepicker-buttonpane>button.deep-orange-btn {
  background-color: #FF5722;
}

body .ui-button.deep-orange-btn:enabled:not(:focus):hover,
body .ui-datepicker-buttonpane>button.deep-orange-btn:enabled:not(:focus):hover {
  background-color: #D84315;
}

body .ui-button.deep-orange-btn:focus,
body .ui-datepicker-buttonpane>button.deep-orange-btn:focus {
  outline: 0 none;
  background-color: #ff7e55;
}

body .ui-button.purple-btn,
body .ui-datepicker-buttonpane>button.purple-btn {
  background-color: #673AB7;
}

body .ui-button.purple-btn:enabled:not(:focus):hover,
body .ui-datepicker-buttonpane>button.purple-btn:enabled:not(:focus):hover {
  background-color: #4527A0;
}

body .ui-button.purple-btn:focus,
body .ui-datepicker-buttonpane>button.purple-btn:focus {
  outline: 0 none;
  background-color: #8259cb;
}

body .ui-button.pink-btn,
body .ui-datepicker-buttonpane>button.pink-btn {
  background-color: #f47264;
}

body .ui-button.pink-btn:enabled:not(:focus):hover,
body .ui-datepicker-buttonpane>button.pink-btn:enabled:not(:focus):hover {
  background-color: #AD1457;
}

body .ui-button.pink-btn:focus,
body .ui-datepicker-buttonpane>button.pink-btn:focus {
  outline: 0 none;
  background-color: #ee4c83;
}

body .ui-button.amber-btn,
body .ui-button.ui-warning-button,
body .ui-datepicker-buttonpane>button.amber-btn,
body .ui-datepicker-buttonpane>button.ui-warning-button {
  background-color: #FFC107;
  color: #212121;
}

body .ui-button.amber-btn:enabled:not(:focus):hover,
body .ui-button.ui-warning-button:enabled:not(:focus):hover,
body .ui-datepicker-buttonpane>button.amber-btn:enabled:not(:focus):hover,
body .ui-datepicker-buttonpane>button.ui-warning-button:enabled:not(:focus):hover {
  background-color: #FF8F00;
}

body .ui-button.amber-btn:focus,
body .ui-button.ui-warning-button:focus,
body .ui-datepicker-buttonpane>button.amber-btn:focus,
body .ui-datepicker-buttonpane>button.ui-warning-button:focus {
  outline: 0 none;
  background-color: #ffce3a;
}

body .ui-button.orange-btn,
body .ui-datepicker-buttonpane>button.orange-btn {
  background-color: #FF9800;
}

body .ui-button.orange-btn:enabled:not(:focus):hover,
body .ui-datepicker-buttonpane>button.orange-btn:enabled:not(:focus):hover {
  background-color: #EF6C00;
}

body .ui-button.orange-btn:focus,
body .ui-datepicker-buttonpane>button.orange-btn:focus {
  outline: 0 none;
  background-color: #ffad33;
}

body .ui-button.brown-btn,
body .ui-datepicker-buttonpane>button.brown-btn {
  background-color: #795548;
}

body .ui-button.brown-btn:enabled:not(:focus):hover,
body .ui-datepicker-buttonpane>button.brown-btn:enabled:not(:focus):hover {
  background-color: #4E342E;
}

body .ui-button.brown-btn:focus,
body .ui-datepicker-buttonpane>button.brown-btn:focus {
  outline: 0 none;
  background-color: #996b5b;
}

body .ui-button.indigo-btn,
body .ui-datepicker-buttonpane>button.indigo-btn {
  background-color: #3bafda;
}

body .ui-button.indigo-btn:enabled:not(:focus):hover,
body .ui-datepicker-buttonpane>button.indigo-btn:enabled:not(:focus):hover {
  background-color: #3bafda;
}

body .ui-button.indigo-btn:focus,
body .ui-datepicker-buttonpane>button.indigo-btn:focus {
  outline: 0 none;
  background-color: #606fc7;
}

body .ui-button.blue-btn,
body .ui-button.ui-info-button,
body .ui-datepicker-buttonpane>button.blue-btn,
body .ui-datepicker-buttonpane>button.ui-info-button {
  background-color: #03A9F4;
  color: #ffffff;
}

body .ui-button.blue-btn:enabled:not(:focus):hover,
body .ui-button.ui-info-button:enabled:not(:focus):hover,
body .ui-datepicker-buttonpane>button.blue-btn:enabled:not(:focus):hover,
body .ui-datepicker-buttonpane>button.ui-info-button:enabled:not(:focus):hover {
  background-color: #0277BD;
}

body .ui-button.blue-btn:focus,
body .ui-button.ui-info-button:focus,
body .ui-datepicker-buttonpane>button.blue-btn:focus,
body .ui-datepicker-buttonpane>button.ui-info-button:focus {
  outline: 0 none;
  background-color: #2ebcfc;
}

body .ui-button.flat,
body .ui-datepicker-buttonpane>button.flat {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

body .ui-buttonset:not(.ui-button-danger, ui-button-secondary, ui-button-success, ui-button-info, ui-button-warning) .ui-button:not(.ui-state-disabled):not(.ui-state-active):hover {
  background-color: #3bafda;
}

body .ui-buttonset:not(.ui-button-danger, ui-button-secondary, ui-button-success, ui-button-info, ui-button-warning) .ui-button.ui-state-active {
  background-color: #f47264;
  color: #ffffff;
}

body .ui-buttonset:not(.ui-button-danger, ui-button-secondary, ui-button-success, ui-button-info, ui-button-warning).ui-button-danger:enabled:not(:focus):hover {
  background-color: #C62828;
}

body .ui-buttonset:not(.ui-button-danger, ui-button-secondary, ui-button-success, ui-button-info, ui-button-warning).ui-button-danger:focus {
  outline: 0 none;
  background-color: #f77066;
}

body .ui-togglebutton:not(.ui-state-active):not(.ui-state-disabled):hover {
  background-color: #3bafda;
}

body .ui-togglebutton.ui-state-active {
  background-color: #f47264;
  color: #ffffff;
}

body .ui-splitbutton {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -moz-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
}

body .ui-splitbutton>.ui-button {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

body .ui-splitbutton>.ui-button:active {
  background-color: #606fc7;
}

body .ui-splitbutton .ui-splitbutton-menubutton {
  height: 2.25em;
  -moz-border-radius-topleft: 0;
  -webkit-border-top-left-radius: 0;
  border-top-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

body .ui-splitbutton .ui-menu {
  min-width: 100%;
}

body .ui-fluid .ui-splitbutton .ui-button:first-child {
  width: calc(100% - 2em);
}

body .ui-chkbox {
  display: inline-block;
  vertical-align: middle;
  line-height: 1.25em;
  width: 1.25em;
  height: 1.25em;
  cursor: default;
}

body .ui-chkbox .ui-chkbox-box {
  border: 2px solid #757575;
  width: 1.25em;
  height: 1.25em;
  overflow: hidden;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

body .ui-chkbox .ui-chkbox-box .ui-chkbox-icon {
  font-size: 1.25em;
  margin-left: -2px;
  margin-top: -2px;
}

body .ui-chkbox .ui-chkbox-box.ui-state-active {
  border-color: #3bafda;
  background-color: #3bafda;
}

body .ui-chkbox .ui-chkbox-box.ui-state-focus {
  border-color: #3bafda;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  -moz-transition: box-shadow 0.3s, background-color 0.3s;
  -o-transition: box-shadow 0.3s, background-color 0.3s;
  -webkit-transition: box-shadow 0.3s, background-color 0.3s;
  transition: box-shadow 0.3s, background-color 0.3s;
}

body .ui-radiobutton {
  position: relative;
  margin: 0 .25em 0 0;
  vertical-align: middle;
}

body .ui-radiobutton .ui-radiobutton-box {
  border: 2px solid #757575;
  width: 1.25em;
  height: 1.25em;
  -moz-transition: box-shadow 0.3s;
  -o-transition: box-shadow 0.3s;
  -webkit-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

body .ui-radiobutton .ui-radiobutton-box.ui-state-focus {
  border-color: #3bafda;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  -moz-transition: box-shadow 0.3s, background-color 0.3s;
  -o-transition: box-shadow 0.3s, background-color 0.3s;
  -webkit-transition: box-shadow 0.3s, background-color 0.3s;
  transition: box-shadow 0.3s, background-color 0.3s;
}

body .ui-radiobutton .ui-radiobutton-box.ui-state-active {
  border-color: #3bafda;
  background-color: transparent;
}

body .ui-radiobutton .ui-radiobutton-box .ui-radiobutton-icon {
  display: block;
  box-sizing: border-box;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  font-size: 1em;
  transition: -webkit-transform ease .28s;
  transition: transform ease .28s;
  -webkit-transform: scale(0);
  transform: scale(0);
  margin: 0;
}

body .ui-radiobutton .ui-radiobutton-box .fa-circle {
  background-color: #3bafda;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
}

body .ui-autocomplete-panel {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

body .ui-autocomplete-panel.ui-shadow {
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

body .ui-autocomplete-panel .ui-autocomplete-list {
  padding: 0;
}

body .ui-autocomplete-panel .ui-autocomplete-list .ui-autocomplete-list-item {
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  font-size: 1em;
  padding: 0.625em 0.875em;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  color: #212121;
}

body .ui-autocomplete-panel .ui-autocomplete-list .ui-autocomplete-list-item .ui-autocomplete-query {
  font-weight: 700;
}

body .ui-autocomplete-panel .ui-autocomplete-list .ui-autocomplete-list-item.ui-state-highlight {
  color: #ffffff;
}

body .ui-autocomplete-panel .ui-autocomplete-list .ui-autocomplete-group {
  padding: 0.625em 0.875em;
}

body .ui-autocomplete .ui-autocomplete-dropdown.ui-button.ui-button-icon-only {
  background-color: transparent;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  height: 1.5em;
  width: 1.5em;
  padding: 0;
  right: 0;
  bottom: 0;
  margin-right: 0;
  position: absolute;
}

body .ui-autocomplete .ui-autocomplete-dropdown.ui-button.ui-button-icon-only .ui-button-text {
  display: none;
}

body .ui-autocomplete .ui-autocomplete-dropdown.ui-button.ui-button-icon-only .fa {
  color: #757575;
}

body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container.ui-inputtext {
  box-sizing: border-box;
  padding: 2px;
}

body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container.ui-state-disabled {
  border-bottom: 1px dotted;
}

body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-input-token {
  float: none;
  display: inline-block;
  margin: 0 1px;
  vertical-align: middle;
}

body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-input-token>input {
  padding: 0;
  font-size: 14px;
  margin: 0;
  vertical-align: bottom;
}

body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-token {
  display: inline-block;
  float: none;
  vertical-align: middle;
  font-size: 1em;
}

body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-token .ui-autocomplete-token-label {
  display: inline-block;
  vertical-align: middle;
}

body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-dropdown {
  top: .75em;
}

body .ui-fluid .ui-autocomplete.ui-autocomplete-dd>.ui-inputtext {
  width: 100%;
}

body .ui-fluid .ui-inputgroup .md-inputfield .ui-autocomplete {
  top: 5px;
}

body .ui-dropdown {
  border-width: 0;
  background: transparent no-repeat;
  background-size: 0 2px, 100% 1px;
  background-position: 50% 100%, 50% 100%;
  transition: background-size 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  padding-bottom: 2px;
  box-sizing: border-box;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

body .ui-dropdown .ui-dropdown-trigger {
  height: 1.5em;
  width: 1.5em;
  margin-top: 0;
  padding: 0;
  top: 0;
  margin-right: 0;
}

body .ui-dropdown .ui-dropdown-trigger .fa {
  color: #757575;
  margin-top: 0;
  margin-left: 0;
}

body .ui-dropdown .ui-dropdown-label {
  background: none;
}

body .ui-dropdown .ui-dropdown-label.ui-inputtext {
  font: 1em "Roboto", "Helvetica Neue", sans-serif;
}

body .ui-dropdown.ui-state-focus {
  border-width: 0;
  background-size: 100% 2px, 100% 1px;
  padding-bottom: 2px;
}

body .ui-dropdown.ui-state-focus .ui-dropdown-label {
  padding-bottom: 2px;
}

body .ui-dropdown-panel {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

body .ui-dropdown-panel .ui-dropdown-list {
  padding: 0;
}

body .ui-dropdown-panel .ui-dropdown-item {
  margin: 0;
  font-size: 1em;
  padding: 0.625em 0.875em;
  color: #212121;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

body .ui-dropdown-panel .ui-dropdown-item:hover {
  background-color: #e8e8e8;
  color: #000000;
}

body .ui-dropdown-panel .ui-dropdown-item.ui-state-highlight {
  background-color: #3bafda;
  color: #ffffff;
}

body .ui-dropdown-panel.ui-shadow {
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

body .ui-dropdown-panel .ui-dropdown-filter-container {
  display: block;
  border-bottom: 1px solid #bdbdbd;
  background-color: #3bafda;
  padding: 0.625em 1em;
}

body .ui-dropdown-panel .ui-dropdown-filter-container input {
  color: #ffffff;
}

body .ui-dropdown-panel .ui-dropdown-filter-container input:focus {
  border-bottom-color: #ffffff;
}

body .ui-dropdown-panel .ui-dropdown-filter-container .fa {
  top: .325em;
  right: .75em;
  color: #ffffff;
}

body .ui-multiselect {
  width:100% !important;
  padding-left:1rem !important;
  box-sizing: border-box;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  -moz-transition: border-color 0.3s;
  -o-transition: border-color 0.3s;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
}

body .ui-multiselect .ui-multiselect-label-container {
  padding-right: 1.5em;
}

body .ui-multiselect .ui-multiselect-label-container .ui-multiselect-label {
  padding: 0px 2px 0px 2px;
}

body .ui-multiselect .ui-multiselect-trigger {
  height: 1.5em;
  width: 1.5em;
  margin-top: 0;
  padding: 0;
  top: .063em;
}

body .ui-multiselect .ui-multiselect-trigger .fa {
  color: #757575;
  margin-top: auto;
  margin-left: auto;
}

body .ui-multiselect.ui-state-focus {
  border-bottom: 2px solid #3bafda;
}

body .ui-multiselect-panel.ui-widget {
  padding: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  min-width: 12.5em;
}

body .ui-multiselect-panel.ui-widget .ui-multiselect-header {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  padding: 0.625em 1em;
  position: relative;
}

body .ui-multiselect-panel.ui-widget .ui-multiselect-header .ui-chkbox {
  float: none;
  margin: 0 .5em 0 -.125em;
  position: static;
}

body .ui-multiselect-panel.ui-widget .ui-multiselect-header .ui-chkbox .ui-chkbox-box {
  border-color: #ffffff;
}

body .ui-multiselect-panel.ui-widget .ui-multiselect-header .ui-chkbox .ui-chkbox-box .ui-chkbox-icon {
  border-color: #ffffff;
}

body .ui-multiselect-panel.ui-widget .ui-multiselect-header .ui-chkbox .ui-chkbox-box.ui-state-active .ui-chkbox-icon {
  border-color: #ffffff;
}

body .ui-multiselect-panel.ui-widget .ui-multiselect-header .ui-chkbox .ui-chkbox-box.ui-state-focus {
  background-color: #9fa8da;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

body .ui-multiselect-panel.ui-widget .ui-multiselect-header .ui-multiselect-filter-container {
  width: 70%;
  display: inline-block;
  vertical-align: middle;
  float: none;
  margin-left: 0;
}

body .ui-multiselect-panel.ui-widget .ui-multiselect-header .ui-multiselect-filter-container .fa {
  top: 0;
  left: 0;
}

body .ui-multiselect-panel.ui-widget .ui-multiselect-header .ui-multiselect-filter-container .ui-inputtext {
  padding: 2px;
  padding-left: 1.5em;
  width: 100%;
  box-sizing: border-box;
}

body .ui-multiselect-panel.ui-widget .ui-multiselect-header .ui-multiselect-filter-container .ui-inputtext:focus {
  border-bottom-color: #ffffff;
}

body .ui-multiselect-panel.ui-widget .ui-multiselect-header .ui-multiselect-close {
  position: absolute;
  right: .5em;
  top: .625em;
  height: 1.5em;
  width: 1.5em;
}

body .ui-multiselect-panel.ui-widget .ui-multiselect-header .ui-multiselect-close span {
  color: #ffffff;
}

body .ui-multiselect-panel.ui-widget .ui-multiselect-header .ui-multiselect-close:hover {
  background-color: #9fa8da;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

body .ui-multiselect-panel.ui-widget .ui-multiselect-item {
  font-size: 1em;
  padding: 0.625em 0.875em;
  color: #212121;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

body .ui-multiselect-panel.ui-widget .ui-multiselect-item:not(.ui-state-highlight):hover {
  background-color:#3bafda;
  color: #ffffff;
}
.ui-multiselect-panel{
  position:relative !important;
  min-width:100% !important;
}

body .ui-multiselect-panel.ui-widget .ui-multiselect-item.ui-state-highlight {
  color: #ffffff;
}

body .ui-spinner .ui-spinner-button {
  width: 1em;
  height: .75em;
  padding: 0;
  margin-right: .25em;
  background-color: transparent;
  color: #212121;
  z-index: auto;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

body .ui-spinner .ui-spinner-button .fa-triangle-1-n {
  color: #212121;
}

body .ui-spinner .ui-spinner-button .fa-triangle-1-s {
  color: #212121;
}

body .ui-spinner .ui-spinner-button .fa {
  top: 0px;
  height: .5em;
  color: #757575;
}

body .ui-spinner .ui-spinner-button:enabled:hover,
body .ui-spinner .ui-spinner-button:active {
  background-color: transparent;
}

body .ui-spinner .ui-spinner-up .fa {
  top: .25em;
}

body .ui-spinner .ui-spinner-down .fa {
  top: .167em;
}

body .ui-spinner .ui-spinner-input {
  padding-right: 30px;
}

body .ui-fluid .ui-spinner .ui-spinner-button {
  width: 1em;
  height: .75em;
}

body .ui-fluid .ui-spinner .ui-spinner-button .fa {
  left: auto;
}

body .ui-inputswitch {
  height: 14px;
  width: 34px !important;
  overflow: visible;
  background-color: #9e9e9e;
  border-color: #9e9e9e;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
}

body .ui-inputswitch .ui-inputswitch-handle {
  top: -4px;
  background-color: #ffffff;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  width: 20px !important;
  height: 20px !important;
  -webkit-box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.137255) 0px 1px 1px 0px, rgba(0, 0, 0, 0.117647) 0px 2px 1px -1px;
  -moz-box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.137255) 0px 1px 1px 0px, rgba(0, 0, 0, 0.117647) 0px 2px 1px -1px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.137255) 0px 1px 1px 0px, rgba(0, 0, 0, 0.117647) 0px 2px 1px -1px;
}

body .ui-inputswitch .ui-inputswitch-on {
  visibility: hidden;
}

body .ui-inputswitch .ui-inputswitch-off span,
body .ui-inputswitch .ui-inputswitch-on span {
  visibility: hidden;
}

body .ui-inputswitch.ui-inputswitch-checked {
  background-color: #f48fb1;
  border-color: #f48fb1;
}

body .ui-inputswitch.ui-inputswitch-checked .ui-inputswitch-handle {
  background-color: #f47264;
  color: #ffffff;
}

body .ui-slider .ui-slider-handle {
  background-color: #f47264;
  color: #ffffff;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  width: 1.25em;
  height: 1.25em;
  transform: scale(0.7);
  -moz-transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -o-transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -webkit-transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

body .ui-slider .ui-slider-handle.ui-state-focus {
  transform: scale(1);
}

body .ui-slider .ui-slider-handle:focus {
  outline: 0 none;
}

body .ui-slider.ui-slider-horizontal {
  height: 2px;
  border: 0 none;
  background-color: #bdbdbd;
}

body .ui-slider.ui-slider-horizontal .ui-slider-handle {
  top: -.55em;
}

body .ui-slider.ui-slider-vertical {
  width: 2px;
  border: 0 none;
  background-color: #bdbdbd;
}

body .ui-slider.ui-slider-vertical .ui-slider-handle {
  left: -.55em;
}

body .ui-slider .ui-slider-range {
  background-color: #f47264;
  color: #ffffff;
}

body .ui-slider:not(.ui-state-disabled) .ui-slider-handle:hover {
  transform: scale(1);
}

body .ui-calendar .ui-datepicker-trigger {
  bottom: 2px;
  right: 0;
  background-color: transparent;
  color: #212121;
  height: 1.5em;
  width: 1.5em;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

body .ui-calendar .ui-datepicker-trigger .fa {
  color: #757575;
}

body .ui-calendar .ui-datepicker-trigger:enabled:hover {
  background-color: transparent;
}

body .ui-fluid .ui-calendar.ui-calendar-w-btn .ui-inputtext {
  width: 100%;
}

body .ui-datepicker {
  padding: 0;
  width: 17.5em;
}

body .ui-datepicker.ui-shadow {
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

body .ui-datepicker .ui-datepicker-header {
  padding: 0.625em 1em;
  font-size: 1em;
  background: #3bafda;
  border-color: #3bafda;
  -moz-border-radius-topleft: 2px;
  -webkit-border-top-left-radius: 2px;
  border-top-left-radius: 2px;
  -moz-border-radius-topright: 2px;
  -webkit-border-top-right-radius: 2px;
  border-top-right-radius: 2px;
  -moz-border-radius-bottomleft: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
  -moz-border-radius-bottomright: 0;
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
}

body .ui-datepicker .ui-datepicker-header .ui-datepicker-prev {
  cursor: pointer;
  top: .4em;
  left: .2em;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  color: #ffffff;
}

body .ui-datepicker .ui-datepicker-header .ui-datepicker-prev:before {
  /* content: "keyboard_arrow_left"; */
}

body .ui-datepicker .ui-datepicker-header .ui-datepicker-prev .fa {
  display: none;
}

body .ui-datepicker .ui-datepicker-header .ui-datepicker-prev:hover {
  background-color: #9fa8da;
}

body .ui-datepicker .ui-datepicker-header .ui-datepicker-next {
  cursor: pointer;
  top: .4em;
  right: .2em;
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  color: #ffffff;
}

body .ui-datepicker .ui-datepicker-header .ui-datepicker-next:before {
  /* content: "keyboard_arrow_right"; */
}

body .ui-datepicker .ui-datepicker-header .ui-datepicker-next .fa {
  display: none;
}

body .ui-datepicker .ui-datepicker-header .ui-datepicker-next:hover {
  background-color: #9fa8da;
}

body .ui-datepicker .ui-datepicker-header select.ui-datepicker-month,
body .ui-datepicker .ui-datepicker-header select.ui-datepicker-year {
  font-size: 14px;
}

body .ui-datepicker table {
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse;
  font-size: 14px;
}

body .ui-datepicker thead tr {
  color: #ffffff;
  background: #3bafda;
}

body .ui-datepicker tbody td {
  padding: .25em .125em;
  box-sizing: border-box;
}

body .ui-datepicker tbody td.ui-datepicker-today a {
  color: #ffffff;
  background: #8591d5;
}

body .ui-datepicker tbody td.ui-datepicker-today a.ui-state-active {
  color: #ffffff;
  background-color: #f47264;
  color: #ffffff;
}

body .ui-datepicker tbody td a {
  padding: .25em;
  margin: 0;
  text-align: center;
  color: #212121;
  display: inline-block;
  height: 2.250em;
  width: 2.250em;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  line-height: 1.8em;
}

body .ui-datepicker tbody td a.ui-state-active {
  color: #ffffff;
  background-color: #f47264;
  color: #ffffff;
}

body .ui-datepicker tbody td a:not(.ui-state-active):not(.ui-state-highlight):hover {
  background-color: #e8e8e8;
  color: #000000;
}

body .ui-datepicker tbody td a span {
  display: inline;
  padding: 0;
  text-align: center;
}

body .ui-datepicker .ui-timepicker {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

body .ui-datepicker .ui-timepicker>div a {
  height: 1.5em;
}

body .ui-datepicker .ui-timepicker>div a>span {
  display: inline-block;
  margin-bottom: .25em;
}

body .ui-datepicker .ui-timepicker>div a .fa.fa-angle-up {
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -o-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

body .ui-datepicker .ui-timepicker>div a .fa.fa-angle-up:before {
  content: "keyboard_arrow_up";
}

body .ui-datepicker .ui-timepicker>div a .fa.fa-angle-down {
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -o-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

body .ui-datepicker .ui-timepicker>div a .fa.fa-angle-down:before {
  content: "keyboard_arrow_down";
}

body #ui-datepicker-div {
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

body .ui-rating {
  min-height: 1.5em;
}

body .ui-rating .fa-ban {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  color: #757575;
}

body .ui-rating .fa-ban:before {
  content: "cancel";
}

body .ui-rating .fa-star {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  color: #757575;
}

body .ui-rating .fa-star:before {
  content: "star";
}

body .ui-rating .fa-star-o {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  color: #757575;
}

body .ui-rating .fa-star-o:before {
  content: "star_border";
}

body .ui-chips>ul.ui-inputtext.ui-state-disabled {
  border-bottom: 1px dotted;
}

body .ui-chips>ul.ui-inputtext .ui-chips-input-token {
  padding-bottom: 2px;
  margin-bottom: 2px;
}

body .ui-chips>ul.ui-inputtext .ui-chips-input-token>input {
  padding: 0;
  font-size: 14px;
  margin: 0;
  vertical-align: bottom;
}

body .ui-chips>ul.ui-inputtext .ui-chips-input-token input:disabled {
  width: 0;
}

body .ui-chips>ul.ui-inputtext .ui-chips-token {
  display: inline-block;
  float: none;
  vertical-align: middle;
  font-size: 1em;
  margin-bottom: 2px;
}

body .ui-chips>ul.ui-inputtext .ui-chips-token .ui-chips-token-label {
  display: inline-block;
  vertical-align: middle;
}

body .ui-password-panel.ui-shadow {
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

body .ui-fileupload .ui-fileupload-buttonbar {
  padding: 0.625em 1em;
}

body .ui-fileupload .ui-fileupload-buttonbar .fa-arrowreturnthick-1-n.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

body .ui-fileupload .ui-fileupload-buttonbar .fa-arrowreturnthick-1-n.fa:before {
  content: "file_upload";
}

body .ui-fileupload .ui-fileupload-buttonbar .ui-button {
  background-color: #f47264;
}

body .ui-fileupload .ui-fileupload-buttonbar .ui-button:enabled:hover {
  background-color: #ad1457;
}

body .ui-fileupload .ui-fileupload-content .ui-messages-error .fa {
  color: #ffffff;
}

body .ui-fileupload-choose:not(.ui-state-disabled):hover.ui-button,
body .ui-fileupload-choose.ui-state-focus.ui-button {
  background-color: #d81558;
}

body .ui-fileupload-choose:not(.ui-state-disabled):active.ui-button {
  background-color: #c1134e;
}

body .ui-editor-container .ui-editor-toolbar {
  background-color: #e8e8e8;
}

body .ui-editor-container .ql-picker.ql-expanded .ql-picker-label {
  color: #444444;
}

body .ui-inputgroup .ui-inputgroup-addon {
  border-width: 0 0 1px 0;
  border-color: #bdbdbd;
  color: #757575;
  padding: 2px 2px 1px 2px;
  align-self: flex-end;
}

body .ui-inputgroup .ui-inputgroup-addon.ui-inputgroup-addon-fill {
  background-color: #f7f7f7;
}

body .ui-inputgroup .ui-inputgroup-addon>i {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-self: flex-end;
}

body .ui-inputgroup .ui-inputtext {
  align-self: flex-end;
}

body .ui-inputgroup .md-inputfield {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  display: inherit;
}

body .ui-inputgroup .md-inputfield label {
  top: 5px;
}

body .ui-inputgroup .md-inputfield .ui-inputwrapper-filled~label {
  top: -20px;
}

body .ui-inputgroup .md-inputfield .ui-inputwrapper-focus~label {
  top: -20px;
}

body .ui-inputgroup .md-inputfield .ui-message {
  top: 100%;
}

body .ui-inputgroup .ui-chkbox,
body .ui-inputgroup .ui-radiobutton {
  margin-bottom: .125em;
}

body .ui-selectbutton .ui-button:not(.ui-state-active):not(.ui-state-disabled):hover {
  background-color: #3bafda;
}

body .ui-selectbutton .ui-button.ui-state-active {
  background-color: #f47264;
  color: #ffffff;
}

body .ui-fluid .ui-inputgroup .ui-button-icon-only {
  width: 1.643em;
  height: 1.643em;
  padding: 0;
}

body .ui-fluid .ui-inputgroup .ui-button-icon-only .ui-button-icon-left {
  margin-left: -.45em;
}

body .ui-inputtext.ng-dirty.ng-invalid,
body p-dropdown.ng-dirty.ng-invalid>.ui-dropdown,
body p-autocomplete.ng-dirty.ng-invalid>.ui-autocomplete>.ui-inputtext,
body p-calendar.ng-dirty.ng-invalid>span>.ui-inputtext,
body p-inputmask.ng-dirty.ng-invalid>.ui-inputtext,
body p-checkbox.ng-dirty.ng-invalid .ui-chkbox-box,
body p-radiobutton.ng-dirty.ng-invalid .ui-radiobutton-box,
body p-inputswitch.ng-dirty.ng-invalid .ui-inputswitch,
body p-listbox.ng-dirty.ng-invalid .ui-inputtext,
body p-multiselect.ng-dirty.ng-invalid>.ui-multiselect,
body p-spinner.ng-dirty.ng-invalid>.ui-inputtext,
body p-selectbutton.ng-dirty.ng-invalid .ui-button,
body p-togglebutton.ng-dirty.ng-invalid .ui-button {
  background-image: linear-gradient(to bottom, #e62a10, #e62a10), linear-gradient(to bottom, #e62a10, #e62a10);
}

@media (max-width: 640px) {
  body .ui-buttonset>.ui-button {
    display: block;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
  }
  body .ui-buttonset>.ui-button:first-child {
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px;
  }
  body .ui-buttonset>.ui-button:last-child {
    -moz-border-radius-bottomleft: 3px;
    -webkit-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  body .ui-splitbutton>.ui-button {
    display: inline-block;
  }
  body .ui-splitbutton>.ui-button:first-child {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
  }
  body .ui-splitbutton>.ui-button:last-child {
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

body .ui-panel {
  padding: 0;
}

body .ui-panel .ui-panel-titlebar {
  border: 0 none;
  border-bottom: 1px solid #3bafda;
  padding: 0.625em 1em;
  -moz-border-radius-topleft: 2px;
  -webkit-border-top-left-radius: 2px;
  border-top-left-radius: 2px;
  -moz-border-radius-topright: 2px;
  -webkit-border-top-right-radius: 2px;
  border-top-right-radius: 2px;
  -moz-border-radius-bottomleft: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
  -moz-border-radius-bottomright: 0;
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
}

body .ui-panel .ui-panel-titlebar .ui-panel-title {
  line-height: 1.5em;
}

body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon {
  position: relative;
  width: 1.5em;
  height: 1.5em;
  color: #ffffff;
  margin: 0;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon:hover {
  background-color: #9fa8da;
}

body .ui-panel .ui-panel-content {
  height: 100%;
  box-sizing: border-box;
  padding: 0.625em 1em;
}

body .ui-panel .ui-panel-footer {
  padding: 0.625em 1em;
  border: 0 none;
  border-top: 1px solid #bdbdbd;
  margin: 0;
}

body .ui-fieldset {
  padding: 0.625em 1em;
}

body .ui-fieldset .ui-fieldset-legend {
  padding: 0.625em 1em;
  padding-left: .125em;
  padding-right: .5em;
  color: #3bafda;
}

body .ui-fieldset .ui-fieldset-legend .ui-fieldset-toggler {
  padding: .5em;
  height: 2em;
  width: 2em;
  background-color: #3bafda;
  color: #ffffff;
  font-size: 1.5em;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  margin-right: .5em;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}

body .ui-fieldset .ui-fieldset-legend .ui-fieldset-toggler:hover {
  background-color: #3bafda;
}

body .ui-fieldset .ui-fieldset-legend .ui-fieldset-legend-text {
  color: #3bafda;
}

body .ui-fieldset .ui-fieldset-legend.ui-state-focus {
  background-color: transparent;
}

body .ui-accordion .ui-accordion-header {
  background-color: #3bafda;
  padding: 0;
  color: #ffffff;
  font-size: 1em;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

body .ui-accordion .ui-accordion-header>a {
  padding: 0.625em 1em;
  color: #ffffff;
}

body .ui-accordion .ui-accordion-header>a .ui-accordion-toggle-icon,
body .ui-accordion .ui-accordion-header>a .ui-accordion-header-text {
  vertical-align: middle;
}

body .ui-accordion .ui-accordion-header:not(.ui-state-active):not(.ui-state-disabled):hover {
  background-color: #3bafda;
}

body .ui-accordion .ui-accordion-header.ui-state-active {
  background-color: #f47264;
  color: #ffffff;
  -moz-border-radius-bottomleft: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
  -moz-border-radius-bottomright: 0;
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
}

body .ui-accordion .ui-accordion-header.ui-state-active>a {
  color: #ffffff;
}

body .ui-accordion .ui-accordion-content {
  padding: 0.625em 1em;
  line-height: 1.5em;
}

body .ui-toolbar {
  background-color: #3bafda;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  padding: .625em;
}

body .ui-tabview {
  padding: 0;
}

body .ui-tabview .ui-tabview-nav {
  background-color: #ffffff;
  border: 0 none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

body .ui-tabview .ui-tabview-nav>li {
  padding: 0;
  -moz-transition: border-color 0.3s;
  -o-transition: border-color 0.3s;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
}

body .ui-tabview .ui-tabview-nav>li>a {
  padding: 0.625em 1em;
}

body .ui-tabview .ui-tabview-nav>li>a:focus {
  outline: 0 none;
}

body .ui-tabview .ui-tabview-nav>li>.fa-close {
  margin: .325em 0 0 0;
  -moz-transition: color 0.3s;
  -o-transition: color 0.3s;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  color: #757575;
}

body .ui-tabview .ui-tabview-nav>li .ui-tabview-left-icon,
body .ui-tabview .ui-tabview-nav>li .ui-tabview-right-icon {
  color: #757575;
  display: inline-block;
  vertical-align: middle;
}

body .ui-tabview .ui-tabview-nav>li .ui-tabview-title {
  line-height: 1.5em;
  vertical-align: middle;
}

body .ui-tabview .ui-tabview-nav>li.ui-state-default a {
  color: #3bafda;
}

body .ui-tabview .ui-tabview-nav>li:not(.ui-state-active):not(.ui-state-disabled):hover {
  background-color: #ffffff;
}

body .ui-tabview .ui-tabview-nav>li.ui-state-active {
  background-color: #3bafda;
  border-color: #3bafda;
  border-style: solid;
}

body .ui-tabview .ui-tabview-nav>li.ui-state-active a {
  color: #ffffff;
}

body .ui-tabview .ui-tabview-nav>li.ui-state-active>.fa-close {
  color: #f47264;
}

body .ui-tabview .ui-tabview-nav>li.ui-tabview-outline {
  outline: 0 none;
  border-color: #f48fb1;
}

body .ui-tabview .ui-tabview-panel {
  padding: 0.625em 1em;
}

body .ui-tabview.ui-tabview-top>.ui-tabview-nav {
  padding: 0;
  margin: 0;
  -moz-border-radius-topleft: 4px;
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-topright: 4px;
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  /*border-bottom: 1px solid #bdbdbd;*/
}

body .ui-tabview.ui-tabview-top>.ui-tabview-nav>li {
  border-style: solid;
  border-width: 0 0 3px 0;
}
.ui-tabview.ui-tabview-top>.ui-tabview-nav li{
  border-color:#757575;
}
body .ui-tabview.ui-tabview-bottom>.ui-tabview-nav {
  padding: 0;
  margin: 0;
  -moz-border-radius-bottomleft: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: 1px solid #bdbdbd;
}

body .ui-tabview.ui-tabview-bottom>.ui-tabview-nav>li {
  border-width: 2px 0 0 0;
}

body .ui-tabview.ui-tabview-left>.ui-tabview-nav {
  padding: 0;
  margin: 0;
  -moz-border-radius-topleft: 4px;
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-right: 1px solid #bdbdbd;
}

body .ui-tabview.ui-tabview-left>.ui-tabview-nav>li {
  box-sizing: border-box;
  border-width: 0 2px 0 0;
}

body .ui-tabview.ui-tabview-left>.ui-tabview-nav>li>a {
  width: 100%;
  box-sizing: border-box;
}

body .ui-tabview.ui-tabview-right>.ui-tabview-nav {
  padding: 0;
  -moz-border-radius-topright: 4px;
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: 1px solid #bdbdbd;
}

body .ui-tabview.ui-tabview-right>.ui-tabview-nav>li {
  box-sizing: border-box;
  border-width: 0 0 0 2px;
}

body .ui-tabview.ui-tabview-right>.ui-tabview-nav>li>a {
  width: 100%;
  box-sizing: border-box;
}

body .ui-tabview.ui-tabview-right>.ui-tabview-nav>li.ui-state-active>a {
  padding-left: 0.875em;
}

@media (max-width: 640px) {
  body .ui-toolbar .ui-toolbar-group-right {
    margin-top: 1em;
  }
}

body .ui-picklist .ui-picklist-caption {
  font-size: 1em;
  padding: 0.625em 1em;
}

body .ui-picklist .ui-picklist-list {
  padding: 0;
}

body .ui-picklist .ui-picklist-filter-container .fa {
  left: 0.3em;
}

body .ui-picklist .ui-picklist-filter-container .ui-picklist-filter {
  text-indent: 1.5em;
}

body .ui-picklist li.ui-picklist-item {
  font-size: 1em;
  padding: 0.625em 0.875em;
  margin: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  color: #212121;
}

body .ui-picklist li.ui-picklist-item:not(.ui-state-highlight):hover {
  background-color: #e8e8e8;
  color: #000000;
}

body .ui-picklist li.ui-picklist-item.ui-state-highlight {
  background-color: #f47264;
  color: #ffffff;
}

body .ui-picklist .ui-picklist-buttons {
  width: 3em;
}

body .ui-picklist .ui-picklist-buttons .ui-button.ui-button-icon-only {
  width: 2.5em;
  margin-right: 0;
  display: inline-block;
  margin-bottom: .5em;
}

body .ui-picklist .ui-picklist-buttons-cell {
  text-align: center;
}

body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons .ui-button.ui-button-icon-only {
  margin: 0 auto;
  display: block;
  margin-bottom: .5em;
}

body .ui-picklist.ui-picklist-responsive .ui-picklist-list .ui-picklist-item .ui-chkbox {
  margin-right: .5em;
  vertical-align: top;
}

body .ui-picklist.ui-picklist-responsive .ui-picklist-list .ui-picklist-item .ui-chkbox,
body .ui-picklist.ui-picklist-responsive .ui-picklist-list .ui-picklist-item .ui-chkbox * {
  box-sizing: content-box;
}

body .ui-organizationchart .ui-organizationchart-node-content.ui-organizationchart-selectable-node:not(.ui-state-highlight):hover {
  background-color: #e8e8e8;
  color: #000000;
}

body .ui-organizationchart .ui-organizationchart-node-content.ui-state-highlight .ui-node-toggler i {
  color: #7b0c32;
}

body .ui-organizationchart .ui-organizationchart-line-down {
  background-color: #bdbdbd;
}

body .ui-organizationchart .ui-organizationchart-line-left {
  border-right: 1px solid #bdbdbd;
}

body .ui-organizationchart .ui-organizationchart-line-top {
  border-top: 1px solid #bdbdbd;
}

body .ui-organizationchart .ui-organizationchart-node-content {
  border-color: #bdbdbd;
}

body .ui-organizationchart .ui-organizationchart-node-content .ui-node-toggler {
  bottom: -1.3em;
  margin-left: -.75em;
  color: #bdbdbd;
}

body .ui-organizationchart .ui-organizationchart-node-content .ui-node-toggler .fa-chevron-down {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

body .ui-organizationchart .ui-organizationchart-node-content .ui-node-toggler .fa-chevron-down:before {
  content: "keyboard_arrow_down";
}

body .ui-organizationchart .ui-organizationchart-node-content .ui-node-toggler .fa-chevron-up {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

body .ui-organizationchart .ui-organizationchart-node-content .ui-node-toggler .fa-chevron-up:before {
  content: "keyboard_arrow_up";
}

body .ui-orderlist .ui-orderlist-caption {
  font-size: 1em;
  padding: 0.625em 1em;
  box-sizing: border-box;
}

body .ui-orderlist .ui-orderlist-list {
  padding: 0;
  box-sizing: border-box;
}

body .ui-orderlist .ui-orderlist-list li.ui-orderlist-item {
  font-size: 1em;
  padding: 0.625em 0.875em;
  margin: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  color: #212121;
}

body .ui-orderlist .ui-orderlist-list li.ui-orderlist-item:not(.ui-state-highlight):hover {
  background-color: #e8e8e8;
  color: #000000;
}

body .ui-orderlist .ui-orderlist-list li.ui-orderlist-item.ui-state-highlight {
  background-color: #f47264;
  color: #ffffff;
}

body .ui-orderlist.ui-orderlist-responsive .ui-orderlist-controls {
  width: 3.75em;
}

body .ui-orderlist .ui-orderlist-controls {
  width: 3.75em;
  text-align: center;
}

body .ui-orderlist .ui-orderlist-controls .ui-button.ui-button-icon-only {
  width: 2.5em;
  margin-bottom: .5em;
  margin-right: 0;
}

body .ui-paginator {
  padding: 0.625em 1em;
}

body .ui-paginator>a {
  box-sizing: border-box;
  color: #000;
}

body .ui-paginator>a .fa {
  display: none;
}

body .ui-paginator>a:not(.ui-state-disabled):not(.ui-state-active):hover {
  background-color: #9fa8da;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

body .ui-paginator .ui-paginator-next {
  padding: 0;
  vertical-align: middle;
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

body .ui-paginator .ui-paginator-next:before {
  /* content: "navigate_next"; */
}

body .ui-paginator .ui-paginator-last {
  padding: 0;
  vertical-align: middle;
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

body .ui-paginator .ui-paginator-last:before {
  /* content: "last_page"; */
}

body .ui-paginator .ui-paginator-prev {
  padding: 0;
  vertical-align: middle;
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

body .ui-paginator .ui-paginator-prev:before {
  /* content: "navigate_before"; */
}

body .ui-paginator .ui-paginator-first {
  padding: 0;
  vertical-align: middle;
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

body .ui-paginator .ui-paginator-first:before {
  /* content: "first_page"; */
}

body .ui-paginator .ui-paginator-pages {
  vertical-align: middle;
  margin: 0 .375em 0 .375em;
}

body .ui-paginator .ui-paginator-pages a {
  padding: 0;
  color: #000;
  min-width: 1.5em;
  min-height: 1.5em;
  line-height: 1.5em;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

body .ui-paginator .ui-paginator-pages a:not(.ui-state-active):hover {
  background-color: #9fa8da;
}

body .ui-paginator .ui-paginator-pages a.ui-state-active {
  background-color: #3bafda;
  color: #ffffff;
}

body .ui-datagrid .ui-datagrid-header {
  padding: 0.625em 1em;
}

body .ui-datagrid .ui-panel .ui-panel-titlebar {
  background-color: #ffffff;
  color: #212121;
  border-color: #bdbdbd;
}

body .ui-datalist .ui-datalist-header {
  padding: 0.625em 1em;
}

body .ui-table .ui-table-caption,
body .ui-table .ui-table-summary {
  padding: 0.625em 1em;
  text-align: center;
  font-weight: normal;
}
body .ui-table .ui-table-caption input{
   padding:0.4em;
}
body .ui-table .ui-table-caption {
  border-bottom: 0 none;
  padding:1.5em 0.20em;
  border: none !important;
  float: right;
}

body .ui-table .ui-table-summary {
  border-top: 0 none;
}

body .ui-table .ui-table-thead>tr>th,
body .ui-table .ui-table-tbody>tr>td,
body .ui-table .ui-table-tfoot>tr>td {
  padding: 0.625em 1em;
}

body .ui-table .ui-table-thead>tr>th,
body .ui-table .ui-table-tfoot>tr>td {
  font-weight: 400;
  color: #212121;
  background-color: #ffffff;
  border: 1px solid #cacaca;
}

body .ui-table .ui-table-thead tr .ui-inputtext {
  width: 100%;
}

body .ui-table .ui-table-scrollable-header {
  border: none;
}

body .ui-table .ui-table-loading-content .fa-spin {
  -webkit-animation: 2s linear infinite fa-spin;
  animation: 2s linear infinite fa-spin;
  color: #ffffff;
}

body .ui-table .ui-sortable-column {
  color: #212121;
}

body .ui-table .ui-sortable-column:not(.ui-state-highlight):hover {
  background-color: #e8e8e8;
  color: #000000;
}

body .ui-table .ui-sortable-column.ui-state-highlight {
  background-color: #f47264;
  color: #ffffff;
  border-top-color: #f47264;
}

body .ui-table .ui-sortable-column.ui-state-highlight .fa {
  color: #ffffff;
}

body .ui-table .ui-sortable-column .ui-sortable-column-icon {
  vertical-align: middle;
  color: #757575;
}

body .ui-table .ui-table-tbody>tr {
  background: #ffffff;
}

body .ui-table .ui-table-tbody>tr>td {
  background: inherit;
  border: 1px solid #cacaca;
}

body .ui-table .ui-table-tbody>tr.ui-state-highlight {
  background-color: #f47264;
  color: #ffffff;
}

body .ui-table .ui-table-tbody>tr.ui-contextmenu-selected {
  background-color: #f47264;
  color: #ffffff;
}

body .ui-table .ui-table-tbody>tr:nth-child(odd) {
  background-color: #f4f4f4;
}

body .ui-table .ui-table-tbody>tr:nth-child(odd).ui-state-highlight {
  background-color: #f47264;
  color: #ffffff;
}

body .ui-table .ui-table-tbody>tr:nth-child(odd).ui-contextmenu-selected {
  background-color: #f47264;
  color: #ffffff;
}

body .ui-table.ui-table-hoverable-rows .ui-table-tbody>tr:not(.ui-state-highlight):hover {
  cursor: pointer;
  background-color: #e8e8e8;
  color: #000000;
}

@media (max-width: 35em) {
  body .ui-table-responsive .ui-table-tbody>tr {
    border-top: 1px solid #cacaca;
    border-bottom: 1px solid #cacaca;
  }
  body .ui-table-responsive .ui-table-tbody>tr.ui-state-highlight {
    background-color: #f47264;
    color: #ffffff;
  }
}

@media screen and (max-width: 40em) {
  body .ui-table-responsive .ui-table-tbody>tr>td {
    border: 0 none;
  }
}

body .ui-datatable .ui-datatable-header,
body .ui-datatable .ui-datatable-footer {
  padding: 0.625em 1em;
}

body .ui-datatable .ui-datatable-header .ui-inputtext,
body .ui-datatable .ui-datatable-footer .ui-inputtext {
  color: #ffffff;
}

body .ui-datatable .ui-datatable-header .ui-inputtext:focus,
body .ui-datatable .ui-datatable-footer .ui-inputtext:focus {
  border-color: #ffffff;
}

body .ui-datatable .ui-paginator {
  padding: 0.625em 1em;
}

body .ui-datatable .ui-datatable-thead>tr {
  border-color: #cacaca;
}

body .ui-datatable .ui-datatable-thead>tr th {
  padding: .625em .875em;
  background-color: #ffffff;
}

body .ui-datatable .ui-datatable-thead>tr th.ui-sortable-column:not(.ui-state-active):hover {
  background-color: #e8e8e8;
  color: #000000;
}

body .ui-datatable .ui-datatable-thead>tr th .ui-column-title {
  display: inline-block;
  vertical-align: middle;
}

body .ui-datatable .ui-datatable-thead>tr th .ui-sortable-column-icon {
  vertical-align: middle;
  color: #757575;
}

body .ui-datatable .ui-datatable-thead>tr th.ui-state-active,
body .ui-datatable .ui-datatable-thead>tr th.ui-state-highlight {
  background-color: #f47264;
  color: #ffffff;
  border-top-color: #f47264;
}

body .ui-datatable .ui-datatable-thead>tr th.ui-state-active .fa,
body .ui-datatable .ui-datatable-thead>tr th.ui-state-highlight .fa {
  color: #ffffff;
}

body .ui-datatable .ui-datatable-thead>tr th.ui-state-active .ui-inputtext,
body .ui-datatable .ui-datatable-thead>tr th.ui-state-highlight .ui-inputtext {
  color: #ffffff;
}

body .ui-datatable .ui-datatable-thead>tr th.ui-state-active .ui-inputtext.ui-state-focus,
body .ui-datatable .ui-datatable-thead>tr th.ui-state-highlight .ui-inputtext.ui-state-focus {
  border-color: #ffffff;
}

body .ui-datatable .ui-datatable-thead>tr th.ui-selection-column .ui-chkbox-box {
  box-sizing: content-box;
}

body .ui-datatable tfoot th {
  padding: .625em .875em;
  border: 1px solid #bdbdbd;
  background-color: #ffffff;
}

body .ui-datatable .ui-datatable-data tr.ui-datatable-even {
  background-color: #f4f4f4;
}

body .ui-datatable .ui-datatable-data tr.ui-datatable-even.ui-state-highlight {
  background-color: #f47264;
  color: #ffffff;
}

body .ui-datatable .ui-datatable-data tr {
  border: 1px solid #cacaca;
}

body .ui-datatable .ui-datatable-data tr td {
  padding: .625em .875em;
}

body .ui-datatable .ui-datatable-data tr td .ui-row-toggler {
  display: inherit;
}

body .ui-datatable .ui-datatable-data tr td.ui-state-highlight .ui-inputtext {
  color: #ffffff;
  border-color: #ffffff;
}

body .ui-datatable .ui-datatable-data tr td.ui-state-highlight .ui-inputtext:focus {
  border-color: #ffffff;
}

body .ui-datatable .ui-datatable-data tr td.ui-state-error {
  background-color: #e62a10;
  border-color: #e62a10;
  color: #ffffff;
}

body .ui-datatable .ui-datatable-data tr td input.ui-cell-editor {
  padding: 2px 2px 1px 2px;
  font-size: 1em;
}

body .ui-datatable .ui-datatable-data tr td.ui-selection-column .ui-chkbox-box,
body .ui-datatable .ui-datatable-data tr td.ui-selection-column .ui-radiobutton-box {
  box-sizing: content-box;
}

body .ui-datatable .ui-datatable-data tr.ui-state-highlight {
  background-color: #f47264;
  border-color: #f47264;
  color: #ffffff;
}

body .ui-datatable .ui-datatable-data tr .ui-cell-editor-input input {
  color: #ffffff;
}

body .ui-datatable .ui-datatable-data tr.ui-state-error {
  background-color: #e62a10;
  border-color: #e62a10;
  color: #ffffff;
}

body .ui-datatable .ui-datatable-data tr.ui-state-error .ui-inputtext,
body .ui-datatable .ui-datatable-data tr.ui-state-error .ui-inputtext.ui-state-error {
  border-color: #ffffff;
}

body .ui-datatable .ui-datatable-data tr.ui-state-highlight td.ui-selection-column .ui-radiobutton-box {
  border-color: #ffffff;
}

body .ui-datatable .ui-datatable-data tr.ui-state-highlight td.ui-selection-column .ui-radiobutton-box .ui-radiobutton-icon {
  background-color: #ffffff;
}

body .ui-datatable .ui-datatable-data tr.ui-state-highlight td.ui-selection-column .ui-chkbox-box {
  border-color: #ffffff;
  background-color: #ffffff;
}

body .ui-datatable .ui-datatable-data tr.ui-state-highlight td.ui-selection-column .ui-chkbox-box .ui-chkbox-icon {
  color: #757575;
}

body .ui-datatable .ui-datatable-data tr.ui-state-highlight .ui-inputtext {
  color: #ffffff;
  border-color: #ffffff;
}

body .ui-datatable .ui-datatable-data tr.ui-state-highlight .ui-inputtext:focus {
  border-color: #ffffff;
}

body .ui-datatable .ui-datatable-data tr.ui-rowgroup-header td a {
  height: 1.5em;
  vertical-align: top;
  display: inline-block;
}

body .ui-datatable .ui-datatable-data.ui-datatable-hoverable-rows>tr.ui-widget-content:not(.ui-state-highlight):hover {
  cursor: pointer;
  background-color: #e8e8e8;
  color: #000000;
}

body .ui-datatable.ui-datatable-scrollable .ui-datatable-scrollable-header,
body .ui-datatable.ui-datatable-scrollable .ui-datatable-scrollable-footer {
  border: 0 none;
  background-color: transparent;
}

body .ui-datatable.ui-datatable-scrollable .ui-datatable-scrollable-header .ui-datatable-data td,
body .ui-datatable.ui-datatable-scrollable .ui-datatable-scrollable-footer .ui-datatable-data td {
  color: #212121;
}

body .ui-datatable.ui-datatable-scrollable thead tr th {
  color: #212121;
  font-size: 14px;
}

body .ui-datatable.ui-datatable-scrollable tfoot tr td {
  color: #212121;
  font-size: 14px;
}

body .ui-datatable .ui-datatable-loading {
  opacity: 0.3;
  filter: alpha(opacity=30);
}

body .ui-datatable .ui-datatable-loading-content {
  top: 35%;
}

body .ui-datatable .ui-datatable-loading-content .fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  -webkit-animation-delay: 0;
  -webkit-animation-duration: 1000ms;
  -webkit-animation-name: spin;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-delay: 0;
  -moz-animation-duration: 1000ms;
  -moz-animation-name: spin;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  animation-delay: 0;
  animation-duration: 1000ms;
  animation-name: spin;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  font-size: 2em;
}

body .ui-datatable .ui-datatable-loading-content .fa:before {
  content: "refresh";
}

body .ui-carousel {
  padding: 0;
}

body .ui-carousel .ui-carousel-header {
  padding: 0.625em 1em;
  font-size: 1em;
  overflow: visible;
}

body .ui-carousel .ui-carousel-header .ui-carousel-header-title {
  overflow: visible;
}

body .ui-carousel .ui-carousel-header .fa {
  color: #ffffff;
}

body .ui-carousel .ui-carousel-header .ui-carousel-button {
  margin: -1px 0 0 0;
}

body .ui-carousel .ui-carousel-header .ui-carousel-page-links {
  margin: -1px 0 0 0;
}

body .ui-tree {
  padding: 0.625em 1em/2;
}

body .ui-tree .ui-treenode-children {
  padding-left: 1.75em;
}

body .ui-tree .ui-treenode-content .ui-chkbox {
  margin-right: .5em;
}

body .ui-tree .ui-treenode-content .ui-chkbox .fa {
  color: #757575;
}

body .ui-tree .ui-treenode-content .ui-tree-toggler {
  vertical-align: middle;
  margin: 0 0 0 .25em;
}

body .ui-tree .ui-treenode-content .ui-treenode-icon {
  vertical-align: middle;
  margin: 0 .25em;
}

body .ui-tree .ui-treenode-content .ui-chkbox {
  margin: 0 .25em;
}

body .ui-tree .ui-treenode-content .ui-treenode-label {
  margin: 0;
  vertical-align: middle;
}

body .ui-tree .ui-treenode-content.ui-treenode-selectable .ui-treenode-label:not(.ui-state-highlight):hover {
  background-color: #e8e8e8;
  color: #000000;
}

body .ui-tree.ui-tree-horizontal {
  padding-left: 0;
  padding-right: 0;
}

body .ui-tree.ui-tree-horizontal .ui-treenode-content {
  background-color: #ffffff;
  border: 1px solid #bdbdbd;
}

body .ui-tree.ui-tree-horizontal .ui-treenode-content .ui-tree-toggler {
  vertical-align: top;
}

body .ui-tree.ui-tree-horizontal .ui-treenode-content .ui-treenode-icon {
  vertical-align: top;
  margin-right: .25em;
}

body .ui-tree.ui-tree-horizontal .ui-treenode-content.ui-treenode-selectable:hover {
  background-color: #e8e8e8;
  color: #000000;
}

body .ui-tree.ui-tree-horizontal .ui-treenode-content.ui-state-highlight {
  background-color: #f47264;
  color: #ffffff;
}

body .ui-tree-draghelper {
  border: 1px solid #3bafda;
}

body .fc .fc-toolbar .ui-icon-circle-triangle-e {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  margin-top: .15em;
}

body .fc .fc-toolbar .ui-icon-circle-triangle-e:before {
  content: "play_circle_outline";
}

body .fc .fc-toolbar .ui-icon-circle-triangle-w {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  margin-top: .15em;
}

body .fc .fc-toolbar .ui-icon-circle-triangle-w:before {
  content: "play_circle_outline";
}

body .fc .fc-toolbar .ui-state-active {
  background-color: #f47264;
}

body .fc .fc-event {
  background-color: #9fa8da;
  color: #ffffff;
}

body .fc table {
  box-sizing: border-box;
}

body .ui-treetable .ui-treetable-header {
  padding: 0.625em 1em;
  font-size: 1em;
}

body .ui-treetable thead tr {
  border-bottom: 1px solid #bdbdbd;
}

body .ui-treetable thead tr th {
  background-color: #ffffff;
  padding: .625em .875em;
  border: 0 none;
}

body .ui-treetable thead tr th .fa {
  color: #757575;
}

body .ui-treetable thead tr th:first-child {
  border-left: 1px solid #bdbdbd;
}

body .ui-treetable thead tr th:last-child {
  border-right: 1px solid #bdbdbd;
}

body .ui-treetable thead tr th .ui-sortable-column-icon {
  vertical-align: middle;
  margin: -.25em 0 0 0;
}

body .ui-treetable thead tr th.ui-state-active {
  background-color: #f47264;
  color: #ffffff;
}

body .ui-treetable thead tr th.ui-state-active .fa {
  color: #ffffff;
}

body .ui-treetable thead tr th .ui-column-resizer {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  font-size: 1em;
  color: #757575;
}

body .ui-treetable thead tr th .ui-column-resizer:before {
  content: "code";
}

body .ui-treetable tfoot td {
  border: 0 none;
  padding: .625em .875em;
}

body .ui-treetable tbody .ui-treetable-row {
  background-color: #ffffff;
}

body .ui-treetable tbody .ui-treetable-row td {
  border: 0 none;
  padding: .625em .875em;
}

body .ui-treetable tbody .ui-treetable-row td .ui-treetable-toggler {
  display: inline-block;
  vertical-align: middle;
  margin: 0 .167em;
  float: none;
}

body .ui-treetable tbody .ui-treetable-row td .ui-chkbox {
  margin-right: .5em;
}

body .ui-treetable tbody .ui-treetable-row td.ui-treetable-child-table-container {
  padding: 0;
}

body .ui-treetable tbody .ui-treetable-row.ui-treetable-row-selectable:not(.ui-state-highlight):hover {
  background-color: #e8e8e8;
  color: #000000;
}

body .ui-treetable tbody .ui-treetable-row.ui-state-highlight {
  background-color: #f47264;
  color: #ffffff;
}

body .ui-treetable tbody .ui-treetable-row.ui-state-highlight .ui-chkbox .ui-chkbox-box {
  border-color: #ffffff;
}

body .ui-treetable.ui-treetable-scrollable .ui-treetable-scrollable-header,
body .ui-treetable.ui-treetable-scrollable .ui-treetable-scrollable-footer {
  background-color: transparent;
  border: 0 none;
}

body .ui-treetable.ui-treetable-scrollable thead th {
  background-color: #ffffff;
  color: #212121;
}

@media (max-width: 40em) {
  body .ui-orderlist.ui-orderlist-responsive .ui-orderlist-controls {
    width: 100%;
  }
}

@media (max-width: 640px) {
  body .ui-picklist.ui-picklist-responsive .ui-picklist-list-wrapper {
    margin-bottom: .5em;
  }
  body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons {
    padding: .5em 0;
  }
  body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons .ui-button.ui-button-icon-only {
    display: inline-block;
    margin-right: .25em;
    margin-bottom: 0;
  }
  body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons .fa-angle-right {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons .fa-angle-right.fa {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
  }
  body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons .fa-angle-right.fa:before {
    content: "play_arrow";
  }
  body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons .fa-angle-double-right {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons .fa-angle-double-right.fa {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
  }
  body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons .fa-angle-double-right.fa:before {
    content: "skip_previous";
  }
  body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons .fa-angle-left {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons .fa-angle-left.fa {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
  }
  body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons .fa-angle-left.fa:before {
    content: "play_arrow";
  }
  body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons .fa-angle-double-left {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons .fa-angle-double-left.fa {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
  }
  body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons .fa-angle-double-left.fa:before {
    content: "skip_next";
  }
  body .ui-orderlist.ui-grid-responsive .ui-orderlist-controls {
    text-align: center;
    width: auto;
  }
  body .ui-orderlist.ui-grid-responsive .ui-orderlist-controls .ui-button {
    margin-right: .25em;
  }
}

body .ui-breadcrumb {
  padding: 0.625em 1em;
}

body .ui-breadcrumb ul li a {
  color: #ffffff;
  font-size: 1em;
}

body .ui-steps {
  position: relative;
}

body .ui-steps .ui-steps-item {
  background-color: transparent;
}

body .ui-steps .ui-steps-item.ui-state-disabled {
  opacity: 1;
  filter: alpha(opacity=100);
}

body .ui-steps .ui-steps-item .ui-menuitem-link {
  display: inline-block;
  text-align: left;
  background-color: #ffffff;
  overflow: hidden;
}

body .ui-steps .ui-steps-item .ui-menuitem-link .ui-steps-number {
  display: inline-block;
  background-color: #757575;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  padding: .25em .75em;
  font-size: 1em;
  color: #ffffff;
}

body .ui-steps .ui-steps-item .ui-menuitem-link .ui-steps-title {
  display: inline;
  margin-left: .625em;
  color: #757575;
}

body .ui-steps .ui-steps-item.ui-state-highlight .ui-steps-number {
  background-color: #f47264;
}

body .ui-steps .ui-steps-item.ui-state-highlight .ui-steps-title {
  font-weight: 700;
  color: #212121;
}

body .ui-steps .ui-steps-item:last-child .ui-menuitem-link {
  display: block;
}

body .ui-steps:before {
  content: ' ';
  border: 1px solid #bdbdbd;
  width: 90%;
  top: 45%;
  left: 0;
  display: block;
  position: absolute;
}

body .ui-menu .ui-menuitem-link,
body .ui-menubar .ui-menuitem-link,
body .ui-tieredmenu .ui-menuitem-link,
body .ui-contextmenu .ui-menuitem-link,
body .ui-megamenu .ui-menuitem-link,
body .ui-slidemenu .ui-menuitem-link {
  padding: .625em 1em;
  color: #212121;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

body .ui-menu .ui-menuitem-link:hover,
body .ui-menubar .ui-menuitem-link:hover,
body .ui-tieredmenu .ui-menuitem-link:hover,
body .ui-contextmenu .ui-menuitem-link:hover,
body .ui-megamenu .ui-menuitem-link:hover,
body .ui-slidemenu .ui-menuitem-link:hover {
  background-color: #e8e8e8;
  color: #000000;
}

body .ui-menu .ui-menuitem-link:focus,
body .ui-menubar .ui-menuitem-link:focus,
body .ui-tieredmenu .ui-menuitem-link:focus,
body .ui-contextmenu .ui-menuitem-link:focus,
body .ui-megamenu .ui-menuitem-link:focus,
body .ui-slidemenu .ui-menuitem-link:focus {
  /*background-color: #e8e8e8;*/
  color: #000000;
}

body .ui-menu .ui-menuitem-link .ui-menuitem-text,
body .ui-menubar .ui-menuitem-link .ui-menuitem-text,
body .ui-tieredmenu .ui-menuitem-link .ui-menuitem-text,
body .ui-contextmenu .ui-menuitem-link .ui-menuitem-text,
body .ui-megamenu .ui-menuitem-link .ui-menuitem-text,
body .ui-slidemenu .ui-menuitem-link .ui-menuitem-text {
  vertical-align: middle;
}

body .ui-menubar .ui-submenu-list,
body .ui-contextmenu .ui-submenu-list,
body .ui-slidemenu .ui-submenu-list,
body .ui-tieredmenu .ui-submenu-list {
  padding: .5em 0;
}

body .ui-menubar .ui-submenu-list .ui-menuitem,
body .ui-contextmenu .ui-submenu-list .ui-menuitem,
body .ui-slidemenu .ui-submenu-list .ui-menuitem,
body .ui-tieredmenu .ui-submenu-list .ui-menuitem {
  margin: 0;
}

body .ui-menu {
  width: 100%;
  padding: .5em 0;
  background: transparent;
  color: white;
  border:none;
}

body .ui-menu .ui-menuitem {
  margin: 0;

}
body .ui-menu .ui-menuitem .ui-menuitem-link {
  color:white;

}

body .ui-menu .ui-menuitem .ui-menuitem-link .ui-menuitem-icon {
  vertical-align: middle;
}

body .ui-menu.ui-shadow,
body .ui-menu .ui-shadow {
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

body .ui-menu .ui-submenu-header {
  border: 0 none;
  margin: 0;
  padding: .625em 1em;
  border-radius: 0;
}

body .ui-menubar {
  padding: 0;
}

body .ui-menubar .ui-menubar-root-list>.ui-menuitem>.ui-menuitem-link {
  padding: .625em 1em;
}

body .ui-menubar .ui-menuitem .ui-menuitem-link .ui-menuitem-icon {
  vertical-align: middle;
}

body .ui-menubar .ui-menuitem .ui-menuitem-link .ui-submenu-icon {
  vertical-align: middle;
}

body .ui-menubar .ui-menuitem.ui-menuitem-active>.ui-menuitem-link {
  background-color: #e8e8e8;
  color: #000000;
}

body .ui-menubar .ui-menuitem.ui-menuitem-active>.ui-menuitem-link .ui-menuitem-icon {
  vertical-align: middle;
}

body .ui-contextmenu {
  padding: .5em 0;
}

body .ui-contextmenu .ui-menuitem .ui-menuitem-link .ui-menuitem-icon {
  vertical-align: middle;
}

body .ui-contextmenu .ui-menuitem.ui-menuitem-active>.ui-menuitem-link {
  background-color: #e8e8e8;
  color: #000000;
}

body .ui-slidemenu {
  padding: .5em 0;
}

body .ui-slidemenu .ui-menuitem {
  margin: 0;
}

body .ui-slidemenu .ui-menuitem .ui-menuitem-link .ui-menuitem-icon {
  vertical-align: middle;
}

body .ui-slidemenu .ui-menuitem.ui-menuitem-active>.ui-menuitem-link {
  background-color: #e8e8e8;
  color: #000000;
}

body .ui-slidemenu .ui-slidemenu-backward {
  margin-bottom: .4em;
  padding: 0.625em 1em;
  width: 100%;
  background-color: #3bafda;
  box-sizing: border-box;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  border: 0 none;
}

body .ui-slidemenu .ui-slidemenu-backward:hover {
  background-color: #32408f;
}

body .ui-tieredmenu {
  padding: .5em 0;
}

body .ui-tieredmenu .ui-menuitem .ui-menuitem-link .ui-menuitem-icon {
  vertical-align: middle;
}

body .ui-tieredmenu .ui-menuitem.ui-menuitem-active>.ui-menuitem-link {
  background-color: #e8e8e8;
  color: #000000;
}

body .ui-tabmenu {
  padding: 0;
}

body .ui-tabmenu .ui-tabmenu-nav {
  padding: 0;
  background-color: #ffffff;
  border: 0 none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

body .ui-tabmenu .ui-tabmenu-nav>.ui-tabmenuitem {
  top: auto;
  margin: 0 .25em 0 0;
  padding: 0;
  border-style: solid;
  border-width: 0 0 2px 0;
  -moz-transition: border-color 0.3s;
  -o-transition: border-color 0.3s;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
}

body .ui-tabmenu .ui-tabmenu-nav>.ui-tabmenuitem>a {
  padding: 0.625em 1em;
}

body .ui-tabmenu .ui-tabmenu-nav>.ui-tabmenuitem>a:focus {
  outline: 0 none;
}

body .ui-tabmenu .ui-tabmenu-nav>.ui-tabmenuitem>a .ui-menuitem-icon,
body .ui-tabmenu .ui-tabmenu-nav>.ui-tabmenuitem>a .ui-menuitem-text {
  float: none;
  display: inline-block;
  vertical-align: middle;
}

body .ui-tabmenu .ui-tabmenu-nav>.ui-tabmenuitem>a .ui-menuitem-icon {
  font-weight: 1em;
  margin-right: .25em;
}

body .ui-tabmenu .ui-tabmenu-nav>.ui-tabmenuitem.ui-state-default a {
  color: #757575;
}

body .ui-tabmenu .ui-tabmenu-nav>.ui-tabmenuitem.ui-state-default a .fa {
  color: #757575;
}

body .ui-tabmenu .ui-tabmenu-nav>.ui-tabmenuitem:not(.ui-state-active):hover {
  background-color: #ffffff;
}

body .ui-tabmenu .ui-tabmenu-nav>.ui-tabmenuitem.ui-state-active {
  background-color: #ffffff;
  border-color: #f47264;
  border-style: solid;
}

body .ui-tabmenu .ui-tabmenu-nav>.ui-tabmenuitem.ui-state-active a {
  color: #3bafda;
}

body .ui-tabmenu .ui-tabmenu-nav>.ui-tabmenuitem.ui-state-active a .fa {
  color: #3bafda;
}

body .ui-megamenu {
  padding: 0;
}

body .ui-megamenu .ui-megamenu-submenu-header {
  border-radius: 0;
  padding: .5em;
}

body .ui-megamenu .ui-submenu-list {
  padding: 0;
  border: 0 none;
}

body .ui-megamenu .ui-submenu-list .ui-menuitem {
  margin: 0;
}

body .ui-megamenu .ui-menuitem {
  margin: 0;
}

body .ui-megamenu .ui-menuitem .ui-menuitem-link .ui-menuitem-icon {
  vertical-align: middle;
}

body .ui-megamenu .ui-menuitem .ui-menuitem-link .ui-submenu-icon {
  vertical-align: middle;
}

body .ui-megamenu .ui-menuitem.ui-menuitem-active>.ui-menuitem-link {
  background-color: #e8e8e8;
  color: #000000;
}

body .ui-panelmenu .ui-panelmenu-header {
  background-color: #3bafda;
  margin-bottom: 1px;
}

body .ui-panelmenu .ui-panelmenu-header a {
  padding: .625em 1em;
  color: #ffffff;
  font-size: 14px;
  border-bottom: 1px solid #ffffff;
}

body .ui-panelmenu .ui-panelmenu-header .fa {
  color: #ffffff;
  vertical-align: middle;
}

body .ui-panelmenu .ui-panelmenu-header .ui-menuitem-text {
  vertical-align: middle;
}

body .ui-panelmenu .ui-panelmenu-header.ui-state-active {
  background-color: #f47264;
  -moz-border-radius-bottomleft: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
  -moz-border-radius-bottomright: 0;
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: 0;
}

body .ui-panelmenu .ui-panelmenu-header.ui-state-active a,
body .ui-panelmenu .ui-panelmenu-header.ui-state-active .fa {
  color: #ffffff;
}

body .ui-panelmenu .ui-panelmenu-content {
  padding: 0;
}

body .ui-panelmenu .ui-panelmenu-content .ui-menuitem {
  margin: 0;
}

body .ui-panelmenu .ui-panelmenu-content .ui-menuitem .ui-menuitem-link {
  border: 0 none;
  padding: .625em 1em;
  width: 100%;
  min-height: 2.750em;
  box-sizing: border-box;
  color: #212121;
  line-height: 1.5em;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  position: relative;
}

body .ui-panelmenu .ui-panelmenu-content .ui-menuitem .ui-menuitem-link:hover {
  background-color: #e8e8e8;
  color: #000000;
}

body .ui-panelmenu .ui-panelmenu-content .ui-menuitem .ui-menuitem-link .ui-menuitem-text {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

body .ui-panelmenu .ui-panelmenu-content .ui-menuitem .ui-menuitem-link .fa {
  position: static;
  display: inline-block;
  vertical-align: middle;
}

body .ui-panelmenu .ui-menu-parent .ui-menu-list {
  margin-left: 28px;
}

@media (max-width: 640px) {
  body .ui-steps .ui-steps-item .ui-menuitem-link .ui-steps-title {
    display: none;
  }
}

body {
  /* Messages */
}

body .ui-messages {
  padding: 0.625em 1em;
}

body .ui-messages ul {
  display: inline-block;
  margin-left: 0;
}

body .ui-messages.ui-messages-info {
  background-color: #2196F3;
  border-color: #2196F3;
  color: #ffffff;
}

body .ui-messages.ui-messages-warn {
  background-color: #ffc107;
  border-color: #ffc107;
  color: #ffffff;
}

body .ui-messages.ui-messages-error {
  background-color: #e62a10;
  border-color: #e62a10;
  color: #ffffff;
}

body .ui-messages.ui-messages-fatal {
  background-color: #212121;
  border-color: #212121;
  color: #ffffff;
}

body .ui-messages.ui-messages-success {
  background-color: #8BC34A;
  border-color: #8BC34A;
  color: #ffffff;
  width: 30%;
  position: absolute;
  right: 30px;
  top: 79px;
}

body .ui-messages .ui-messages-close {
  text-decoration: none;
  color: #fff;
  right: .25em;
}

body .ui-messages .ui-messages-icon {
  background: none;
  color: #fff;
  margin-top: -2px;
}
body .ui-toast-message-success	{
 background: #82b782 !important;
 color:#fff !important;
}
body .ui-toast-message-error	{
  background: #d17078 !important;
  color:#fff !important;


}
body .ui-messages .ui-messages-icon.fa-info-circle {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  font-size: 1.75em;
}

body .ui-messages .ui-messages-icon.fa-info-circle:before {
  content: "info";
}

body .ui-messages .ui-messages-icon.fa-warning {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  font-size: 1.75em;
}

body .ui-messages .ui-messages-icon.fa-warning:before {
  content: "warning";
}

body .ui-messages .ui-messages-icon.fa-close {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  font-size: 1.75em;
}

body .ui-messages .ui-messages-icon.fa-close:before {
  content: "error_outline";
}

body .ui-messages .ui-messages-icon.fa-check {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  font-size: 1.75em;
}

body .ui-messages .ui-messages-icon.fa-check:before {
  content: "check_circle";
}

body .ui-growl {
  top: 90px;
}

body .ui-growl>.ui-growl-item-container {
  opacity: 1;
}

body .ui-growl>.ui-growl-item-container.ui-growl-message-info {
  background-color: #2196F3;
}

body .ui-growl>.ui-growl-item-container.ui-growl-message-warn {
  background-color: #ffc107;
}

body .ui-growl>.ui-growl-item-container.ui-growl-message-error {
  background-color: #e62a10;
}

body .ui-growl>.ui-growl-item-container.ui-growl-message-fatal {
  background-color: #212121;
}

body .ui-growl>.ui-growl-item-container.ui-growl-message-success {
  background-color: #8BC34A;
}

body .ui-growl>.ui-growl-item-container.ui-shadow {
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

body .ui-growl .ui-growl-item .ui-growl-image {
  background: none;
  color: #ffffff;
  font-size: 36px;
}

body .ui-growl .ui-growl-item .ui-growl-image.fa-info-circle {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  font-size: 1.75em;
}

body .ui-growl .ui-growl-item .ui-growl-image.fa-info-circle:before {
  content: "info";
}

body .ui-growl .ui-growl-item .ui-growl-image.fa-exclamation-circle {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  font-size: 1.75em;
}

body .ui-growl .ui-growl-item .ui-growl-image.fa-exclamation-circle:before {
  content: "warning";
}

body .ui-growl .ui-growl-item .ui-growl-image.fa-close {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  font-size: 1.75em;
}

body .ui-growl .ui-growl-item .ui-growl-image.fa-close:before {
  content: "error_outline";
}

body .ui-growl .ui-growl-item .ui-growl-image.fa-check {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  font-size: 1.75em;
}

body .ui-growl .ui-growl-item .ui-growl-image.fa-check:before {
  content: "check_circle";
}

body .ui-growl .ui-growl-item .ui-growl-message {
  color: #ffffff;
}

body .ui-growl .ui-growl-item .ui-growl-icon-close {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  font-size: 24px;
  color: #ffffff;
}

body .ui-growl .ui-growl-item .ui-growl-icon-close:before {
  content: "close";
}

body .ui-dialog.ui-shadow {
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

body .ui-dialog .ui-dialog-titlebar {
  background-color: #ffffff;
  color: #212121;
  padding: 0.625em 1em;
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-title {
  font-size: 1.25em;
  letter-spacing: .005em;
  margin-top: .25em;
  line-height: 2em;
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon {
  border: 0 none;
  padding: 0;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  height: 1.5em;
  width: 1.5em;
  text-align: center;
  margin-top: .5em;
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon:hover,
body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon:focus {
  background-color: #e8e8e8;
  color: #000000;
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon .fa {
  color: #757575;
  display: inline-block;
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon .fa-extlink.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon .fa-extlink.fa:before {
  content: "fullscreen";
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon .fa-newwin.fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon .fa-newwin.fa:before {
  content: "fullscreen_exit";
}

body .ui-dialog .ui-dialog-content {
  padding: 0.625em 1em;
}

body .ui-dialog .ui-dialog-footer {
  text-align: right;
  border: 0 none;
  border-top: 1px solid #bdbdbd;
}

body .ui-dialog .ui-dialog-footer .ui-button {
  background-color: #ffffff;
  color: #212121;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  width: auto;
}

body .ui-dialog .ui-dialog-footer .ui-button .fa {
  color: #757575;
}

body .ui-dialog .ui-dialog-footer .ui-button:hover {
  background-color: #e8e8e8;
  color: #000000;
}

body .ui-dialog .ui-confirm-dialog-severity {
  margin: 0px .75em;
}

body .ui-lightbox.ui-shadow {
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

body .ui-lightbox .ui-lightbox-caption {
  padding: 0.625em 1em;
}

body .ui-lightbox .ui-lightbox-caption .ui-lightbox-caption-text {
  margin: 0;
}

body .ui-lightbox .ui-lightbox-caption .ui-lightbox-close {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  padding: 0;
  margin: 0;
  width: 1.5em;
  height: 1.5em;
}

body .ui-lightbox .ui-lightbox-caption .ui-lightbox-close:hover {
  background-color: #9fa8da;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-right,
body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-left {
  top: 40%;
}

body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-right .fa,
body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-left .fa {
  -moz-transition: color 0.3s;
  -o-transition: color 0.3s;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  font-size: 3em;
  color: #9fa8da;
}

body .ui-overlaypanel.ui-shadow {
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

body .ui-overlaypanel .ui-overlaypanel-close {
  background-color: #f47264;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  right: -16px;
  top: -16px;
  width: 2em;
  height: 2em;
  line-height: 2em;
  text-align: center;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}

body .ui-overlaypanel .ui-overlaypanel-close span {
  color: #ffffff;
  line-height: inherit;
}

body .ui-overlaypanel .ui-overlaypanel-close:hover {
  background-color: #ad1457;
}

body .ui-tooltip {
  opacity: 0.9;
  filter: alpha(opacity=90);
}

body .ui-tooltip .ui-tooltip-text {
  background-color: #323232;
}

body .ui-tooltip.ui-tooltip-top .ui-tooltip-arrow {
  border-top-color: #323232;
}

body .ui-tooltip.ui-tooltip-bottom .ui-tooltip-arrow {
  border-bottom-color: #323232;
}

body .ui-tooltip.ui-tooltip-left .ui-tooltip-arrow {
  border-left-color: #323232;
}

body .ui-tooltip.ui-tooltip-right .ui-tooltip-arrow {
  border-right-color: #323232;
}

body .ui-draggable-dragging.ui-state-default {
  padding: 0.625em 1em !important;
  background-color: #3bafda;
}

body .jqplot-target {
  font-family: "Roboto", "Helvetica Neue", sans-serif;
}

body .ui-progressbar {
  height: .5em;
  background-color: #9fa8da;
  overflow: hidden;
  border: 0 none;
}

body .ui-progressbar .ui-progressbar-value {
  height: .5em;
}

body .ui-progressbar .ui-progressbar-label {
  color: #ffffff;
  display: none !important;
}

body .ui-progressbar-indeterminate .ui-progressbar-value {
  background-color: inherit;
}

body .ui-progressbar-indeterminate .ui-progressbar-value::after {
  background-color: #3bafda;
}

body .ui-progressbar-indeterminate .ui-progressbar-value::before {
  background-color: #3bafda;
}

body .ui-galleria .ui-galleria-nav-prev {
  left: 0;
}

body .ui-galleria .ui-galleria-nav-next {
  right: 0;
}

body .ui-inplace .ui-inplace-display {
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

body .ui-inplace .ui-inplace-display:hover {
  background-color: #e8e8e8;
}

body .ui-terminal .ui-terminal-input {
  font-size: 14px;
}
.ui-paginator .ui-paginator-current{
  float:left;
  margin:0px;
}
.ui-paginator .ui-dropdown{
  float:right;
}